import { useDispatch } from 'react-redux';
import _ from '@/lodash';
import styles from '@/components/Modal/Modal.module.scss';
import Modal from '@/components/Modal/Modal';
import { Button } from '@/components/Button/Button';
import { fetchPost } from '@/hooks/useFetch';
import { useCurrentUserSelector } from '@/redux/selectors';
import { allStates, valuesToOptions } from '@/helpers';
import { useForm } from 'react-hook-form';
import { FormInput } from '@/components/FormInput/FormInput';

interface AddressFormProps {
  buttonChildren?: any;
  buttonClassName?: string;
}

function AddressForm(props: AddressFormProps) {
  const { buttonChildren = <>Edit</>, buttonClassName = styles.link } = props;

  const userDoc = useCurrentUserSelector();

  const form = useForm({
    defaultValues: {
      licensed_states: valuesToOptions(userDoc?.licensed_states),
      online_therapy: valuesToOptions(userDoc?.online_therapy),
      addressOne: userDoc?.addressOne,
      addressTwo: userDoc?.addressTwo,
      city: userDoc?.city,
      state: valuesToOptions(userDoc?.state),
      zip: userDoc?.zip,
    },
  });

  const dispatch = useDispatch();

  const createSaveChanges = (closeModal: Function) => {
    return (formData: any) => {
      fetchPost(
        '/api/user',
        {
          ..._.mapValues(formData, _.trim),
          state: formData.state?.value,
          online_therapy: formData.online_therapy?.value,
          licensed_states: _.map(formData.licensed_states, 'value'),
        },
        { dispatch },
      );

      closeModal();
    };
  };

  return (
    <Modal
      buttonClassName={buttonClassName}
      buttonChildren={buttonChildren}
      render={({ closeModal }) => (
        <div className={styles.modalForm}>
          <div className={styles.modalHeader}>Practice Details</div>

          <div className="form-field">
            <span className="form-label">
              What states are you licensed to practice in?
            </span>

            <FormInput
              form={form}
              name="licensed_states"
              options={valuesToOptions(allStates)}
              placeholder="Select states..."
              isMulti
              isSearchable
              required
            />
          </div>

          <div className="form-field">
            <span className="form-label">
              Are you available for teletherapy, in-person, or both?
            </span>

            <FormInput
              form={form}
              name="online_therapy"
              options={valuesToOptions([
                'Teletherapy Only',
                'In-Person Only',
                'Both',
              ])}
            />
          </div>

          <div className="form-field">
            <span className="form-label">
              Do you have a physical office? (Optional)
            </span>

            <FormInput
              form={form}
              name="addressOne"
              placeholder="Street address"
              maxLength={70}
              autoComplete="address-line1"
            />
          </div>

          <div className="form-field">
            <FormInput
              form={form}
              name="addressTwo"
              placeholder="Apartment, unit, building, etc."
              maxLength={70}
              autoComplete="address-line2"
            />
          </div>

          <div className="form-field">
            <FormInput
              form={form}
              name="state"
              options={valuesToOptions(allStates)}
              placeholder="State"
              maxMenuHeight={180}
              autoComplete="address-level1"
              isSearchable
              isClearable
            />
          </div>

          <div className="form-field">
            <div className="form-field-split">
              <FormInput
                form={form}
                name="city"
                placeholder="City"
                autoComplete="address-level2"
                maxLength={50}
              />

              <FormInput
                form={form}
                name="zip"
                placeholder="Zip Code"
                autoComplete="postal-code"
                inputMode="numeric"
                maxLength={10}
              />
            </div>
          </div>

          <div className="form-actions">
            <Button className="btn" color="grey" onClick={closeModal}>
              Cancel
            </Button>

            <Button
              className="btn"
              onClick={form.handleSubmit(createSaveChanges(closeModal))}
            >
              Save Changes
            </Button>
          </div>
        </div>
      )}
    />
  );
}

export default AddressForm;
