import { useDispatch } from 'react-redux';
import styles from '@/components/Modal/Modal.module.scss';
import Modal from '@/components/Modal/Modal';
import { Button } from '@/components/Button/Button';
import { fetchPost } from '@/hooks/useFetch';
import { useCurrentUserSelector } from '@/redux/selectors';
import {
  allAgeFocuses,
  allEthnicityFocuses,
  allSexualityFocuses,
} from '@/helpers';
import { FormInput } from '@/components/FormInput/FormInput';
import { useForm } from 'react-hook-form';

interface FocusesFormProps {
  buttonChildren?: any;
  buttonClassName?: string;
}

function FocusesForm(props: FocusesFormProps) {
  const { buttonChildren = <>Edit</>, buttonClassName = styles.link } = props;

  const userDoc = useCurrentUserSelector();

  const form = useForm({
    defaultValues: {
      focuses_age: userDoc?.focuses_age,
      focuses_ethnicity: userDoc?.focuses_ethnicity,
      focuses_sexuality: userDoc?.focuses_sexuality,
    },
  });

  const dispatch = useDispatch();

  const createSaveChanges = (closeModal: Function) => {
    return (formData: any) => {
      fetchPost('/api/user', formData, { dispatch });
      closeModal();
    };
  };

  return (
    <Modal
      buttonClassName={buttonClassName}
      buttonChildren={buttonChildren}
      render={({ closeModal }) => (
        <div className={styles.modalForm}>
          <div className={styles.modalHeader}>Client Focuses</div>

          <div className="form-field">
            <span className="form-label">Age Groups</span>
            <FormInput
              type="toggle-group"
              form={form}
              name="focuses_age"
              options={allAgeFocuses}
            />
          </div>

          <div className="form-field">
            <span className="form-label">Ethnicities Served</span>
            <FormInput
              type="toggle-group"
              form={form}
              name="focuses_ethnicity"
              options={allEthnicityFocuses}
            />
          </div>

          <div className="form-field">
            <span className="form-label">Gender & Sexuality</span>
            <FormInput
              type="toggle-group"
              form={form}
              name="focuses_sexuality"
              options={allSexualityFocuses}
            />
          </div>

          <div className="form-actions">
            <Button className="btn" color="grey" onClick={closeModal}>
              Cancel
            </Button>

            <Button
              className="btn"
              onClick={form.handleSubmit(createSaveChanges(closeModal))}
            >
              Save Changes
            </Button>
          </div>
        </div>
      )}
    />
  );
}

export default FocusesForm;
