import { getProfileLink } from '@/helpers';
import { LoginPayload, loginAction } from '@/redux/actions';
import { Dispatch } from 'react';
import { NavigateFunction } from 'react-router-dom';

export const loginCallback = ({
  res,
  dispatch,
  navigate,
}: {
  res: LoginPayload;
  dispatch: Dispatch<any>;
  navigate: NavigateFunction;
}) => {
  const { url_tag, short_id } = res;

  dispatch(loginAction(res));

  if (short_id) {
    navigate(getProfileLink({ url_tag: url_tag || undefined, short_id }));
  }
};
