import _ from '@/lodash';
import { IoIosInformationCircle } from 'react-icons/io';
import styles from '../Issues/Issues.module.scss';
import profileStyes from '@/layouts/Profile/Profile.module.scss';
import modalStyles from '@/components/Modal/Modal.module.scss';
import { UserDoc } from 'src-server/models';
import Tooltip from '@/components/Tooltip/Tooltip';
import { useIsMyself } from '@/redux/selectors';
import PaymentsForm from '../PaymentsForm/PaymentsForm';

interface PaymentsProps {
  userDoc: UserDoc;
}

function Payments(props: PaymentsProps) {
  const { userDoc } = props;
  const { id, payments, min_cost, max_cost } = userDoc;
  const isMyself = useIsMyself(id);

  const sliding = _.includes(payments, 'Sliding Scale');
  const cash = _.includes(payments, 'Out-of-Pocket');
  const insurances = _.without(payments, 'Out-of-Pocket', 'Sliding Scale');

  const costDisplay =
    min_cost === max_cost ? `$${min_cost}` : `$${min_cost} - ${max_cost}`;

  return (
    <>
      <div className={profileStyes.sidebarHeader}>
        <span>Costs and Insurance</span>

        {isMyself && (
          <PaymentsForm
            buttonClassName={
              _.size(payments) && min_cost
                ? modalStyles.linkComplete
                : undefined
            }
          />
        )}
      </div>

      <div className={styles.container}>
        {min_cost && `${costDisplay} per session`}

        {sliding && (
          <div>
            <Tooltip content="This therapist accepts a range of fees depending on your circumstances, to make therapy more affordable.">
              <div className={styles.slidingScale}>
                <span>Sliding Scale</span>
                <IoIosInformationCircle />
              </div>
            </Tooltip>
          </div>
        )}

        {cash && <div>Out of Pocket</div>}

        {_.isEmpty(payments) && !max_cost ? (
          <div> No listed payment details.</div>
        ) : (
          _.map(_.sortBy(insurances), (insurance) => (
            <div key={insurance}>{insurance}</div>
          ))
        )}
      </div>
    </>
  );
}

export default Payments;
