import { StrictMode } from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import App from './App';

import 'normalize.css';
import './globals.scss';

import store from './redux/store';

const rootElement = document.getElementById('root');

const AppContainer = (
  <StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </HelmetProvider>
  </StrictMode>
);

if (rootElement?.hasChildNodes()) {
  hydrateRoot(rootElement, AppContainer);
} else {
  createRoot(rootElement!).render(AppContainer);
}
