import { useEffect, useState } from 'react';
import styles from '../Login/Login.module.scss';
import Tooltip from '@/components/Tooltip/Tooltip';
import { Button } from '@/components/Button/Button';
import Evil from '@/components/Evil/Evil';
import { fetchPost } from '@/hooks/useFetch';
import { Link, useSearchParams } from 'react-router-dom';
import { HeaderTags } from '@/components/HeaderTags/HeaderTags';
import Skeleton from '@/components/Skeleton/Skeleton';
import { useDispatch } from 'react-redux';
import { useCurrentUserSelector, useLoginSelector } from '@/redux/selectors';
import { useDefaultLink } from '@/helpers';

function VerifyEmail() {
  const userDoc = useCurrentUserSelector();

  const [email, setEmail] = useState(userDoc?.email || '');
  const [isLoading, setIsLoading] = useState(false);
  const [isSendSuccess, setIsSendSuccess] = useState(false);
  const [isVerifySuccess, setIsVerifySuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isRobot, setIsRobot] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const isFilled = Boolean(email);
  const dispatch = useDispatch();
  const login = useLoginSelector();
  const defaultLink = useDefaultLink();

  useEffect(() => {
    const user_id = searchParams.get('id');
    const token = searchParams.get('token');

    if (user_id && token && !isLoading && !isVerifySuccess) {
      setIsLoading(true);

      fetchPost('/auth/verify_email', { user_id, token }, { dispatch })
        .then(() => setIsVerifySuccess(true))
        .catch(() =>
          setErrorMessage(
            'Verification failed, your link may have expired. Please request a new link.',
          ),
        )
        .finally(() => {
          searchParams.delete('id');
          searchParams.delete('token');
          setSearchParams(searchParams);
          setIsLoading(false);
        });
    }
  }, [dispatch, isLoading, isVerifySuccess, searchParams, setSearchParams]);

  if (isLoading) {
    return (
      <div className={styles.loginForm}>
        <Skeleton count={4} />
      </div>
    );
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isRobot) {
      setErrorMessage('Please check the box to confirm you are human.');
      return;
    }

    setErrorMessage('');

    setIsLoading(true);

    fetchPost('/auth/request_verify', { email })
      .then(() => setIsSendSuccess(true))
      .catch(() =>
        setErrorMessage(
          'Sending failed. Please double check your email address.',
        ),
      )
      .finally(() => setIsLoading(false));
  };

  return (
    <form onSubmit={onSubmit} className={styles.loginForm}>
      <HeaderTags
        title="Verify Email | MintLeaf"
        description="Verify your MintLeaf therapist email."
        url="https://mintleaf.co/verify"
        noIndex
      />

      <h1 className={styles.loginHeader}>
        {isVerifySuccess && 'Email verified'}
        {isSendSuccess && 'Email sent'}
        {!isSendSuccess && !isVerifySuccess && 'Verify your email'}
      </h1>

      {isVerifySuccess && !login && (
        <div>
          Your email has been verified. You can now{' '}
          <Link to="/login" className="link primary">
            login
          </Link>{' '}
          to your account.
        </div>
      )}

      {isVerifySuccess && login && (
        <div>
          Your email has been verified. You can now go back to{' '}
          <Link to={defaultLink} className="link primary">
            your profile
          </Link>
          .
        </div>
      )}

      {isSendSuccess && (
        <div>
          Please check your email and click on the link we sent to verify your
          email.
        </div>
      )}

      {!isSendSuccess && !isVerifySuccess && (
        <>
          <div className={styles.resetSubheader}>
            Please enter the email address associated with your account.
          </div>

          <div className={styles.loginFields}>
            <div className="form-field">
              <span className="form-label">Email</span>
              <input
                name="email"
                type="email"
                className={styles.loginInput}
                placeholder="Your Email"
                autoComplete="new-password"
                value={email}
                onChange={(event) => setEmail(event.currentTarget.value)}
              />
            </div>
          </div>

          <Evil setIsRobot={setIsRobot} />

          {errorMessage && (
            <div className={styles.loginError}>{errorMessage}</div>
          )}

          <div className={styles.passwordRow}>
            <Tooltip
              content="Please fill out all fields and checkboxes."
              disabled={isFilled || isLoading}
            >
              <Button
                className="btn"
                type="submit"
                disabled={!isFilled || isLoading}
              >
                Email me a Verification Link
              </Button>
            </Tooltip>
          </div>
        </>
      )}
    </form>
  );
}

export default VerifyEmail;
