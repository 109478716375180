import _ from '@/lodash';
import Skeleton from '@/components/Skeleton/Skeleton';
import { Button } from '@/components/Button/Button';
import QuestionRow from '../QuestionRow/QuestionRow';
import { useLoadDocList } from '@/hooks/useLoadDoc';
import { QuestionDoc, UserDoc } from 'src-server/models';

interface QuestionPageProps {
  params: any;
  createOnClick: any;
  nextPage?: Function;
}

function QuestionPage(props: QuestionPageProps) {
  const { params, createOnClick, nextPage } = props;
  const { result, isSuccess } = useLoadDocList({
    name: 'QuestionPage',
    collection: 'question',
    query: params,
  });

  if (!isSuccess) {
    return (
      <>
        {_.times(5, (index) => (
          <Skeleton key={index} count={4} />
        ))}
      </>
    );
  }

  const { docs, next, page } = result;

  return (
    <>
      {_.isEmpty(docs) && page === 1 ? (
        <div className="card faded">No questions found.</div>
      ) : (
        _.map(docs, ({ id }) => (
          <QuestionRow key={id} question={id} createOnClick={createOnClick} />
        ))
      )}

      {nextPage && next && (
        <div>
          <Button className="btn" onClick={nextPage}>
            See More
          </Button>
        </div>
      )}
    </>
  );
}

export default QuestionPage;
