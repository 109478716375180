import { configureStore } from '@reduxjs/toolkit';
import { loginReducer, collectionsReducer } from './reducers';
import { captureException } from '@sentry/react';

const store = configureStore({
  reducer: { login: loginReducer, collections: collectionsReducer },
});

function persistLogin() {
  const { id, url_tag, short_id } = store.getState().login;
  const safeStorage = getSafeStorage();

  if (id) {
    safeStorage.setItem('id', id);
    safeStorage.setItem('last_seen', new Date().toString());

    if (short_id) {
      safeStorage.setItem('short_id', short_id);
    }

    if (url_tag) {
      safeStorage.setItem('url_tag', url_tag);
    }
  } else {
    safeStorage.clear();
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage#securityerror
export function getSafeStorage() {
  let safeStorage;

  try {
    localStorage.setItem('available', 'true');
    localStorage.removeItem('available');
    safeStorage = localStorage;
  } catch (error) {
    captureException(new Error('LocalStorage not available.'), {
      extra: { error },
    });
    safeStorage = sessionStorage;
  }

  return safeStorage;
}

persistLogin();

store.subscribe(persistLogin);

export default store;
