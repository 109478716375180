import _ from '@/lodash';
import { Helmet } from 'react-helmet-async';
import { useParams, Navigate, useLocation } from 'react-router-dom';
import { getProfileLink } from '@/helpers';
import { useLoadDoc } from '@/hooks/useLoadDoc';
import Profile from '@/layouts/Profile/Profile';
import profileStyles from '@/layouts/Profile/Profile.module.scss';
import { Preload } from '@/components/Preload/Preload';

function LoadProfileUser() {
  const { short_id } = useParams<any>();
  const location = useLocation();
  const isNotNumber = isNaN(_.toNumber(short_id));

  const { doc: userDoc, isSuccess } = useLoadDoc({
    name: 'LoadProfileUser',
    collection: 'user',
    id: short_id,
    query: isNotNumber ? { short_id: -1 } : { short_id },
  });

  if (isNotNumber) {
    return <Navigate to="/therapists" />;
  }

  if (!isSuccess) {
    return <div className={profileStyles.profilePage} />;
  }

  const user = userDoc?.id;

  if (!user) {
    return <Navigate to="/therapists" />;
  }

  const link = getProfileLink(userDoc);

  if (location.pathname !== link) {
    return (
      <>
        <Helmet>
          <meta name="prerender-status-code" content="301" />
          <meta
            name="prerender-header"
            content={`Location: https://mintleaf.co${link}`}
          />
        </Helmet>

        <Navigate to={link} />
      </>
    );
  }

  return (
    <>
      <Preload href={userDoc.photo} />
      <Profile userDoc={userDoc} />
    </>
  );
}

export default LoadProfileUser;
