import { Link } from 'react-router-dom';
import { ResponseDoc } from 'src-server/models';
import { useDocSelector } from '@/redux/selectors';
import Textarea from '@/components/Textarea/Textarea';
import Truncate from '@/components/Truncate/Truncate';
import UserBadge from '../UserBadge/UserBadge';
import { getDisplayName, getProfileLink, getSlug } from '@/helpers';
import Skeleton from '@/components/Skeleton/Skeleton';
import { lazy, Suspense } from 'react';
import LazyLoad from 'react-lazyload';
import Audio from '../Audio/Audio';
import ContactCTA from '../ContactCTA/ContactCTA';

import userStyles from '../User/User.module.scss';
import responseStyles from '../Response/Response.module.scss';
import styles from './Post.module.scss';

const Video = lazy(() => import('../../components/Video/Video'));

interface PostProps {
  author_id: string;
  responseDoc: ResponseDoc;
  preload?: boolean;
}

function Post(props: PostProps) {
  const { responseDoc, preload } = props;
  const {
    video_url,
    video_thumb_url,
    audio_url,
    audio_timeline,
    text,
    type,
    transcript_full,
    author_id,
    title,
    is_complete,
  } = responseDoc;

  const userDoc = useDocSelector({ collection: 'user', id: author_id });

  if (!userDoc) {
    return <Skeleton card count={18} />;
  }

  if (!is_complete) {
    return null;
  }

  const name = getDisplayName(userDoc);
  const url = getProfileLink(userDoc);
  const { headline } = userDoc;

  return (
    <div className={responseStyles.section} id={getSlug(`${name} ${title}`)}>
      <div className={styles.header}>
        <UserBadge user={author_id} preload={preload} />

        <div>
          <Link to={url} className={styles.name}>
            {name}
          </Link>
          <div>{headline}</div>
        </div>
      </div>

      <div className={responseStyles.sectionHeader}>
        <div>{title}</div>
      </div>

      {type === 'video' && (
        <div className={responseStyles.responseVideo}>
          <LazyLoad once offset={100} placeholder={<Skeleton count={4} />}>
            <Suspense fallback={<Skeleton count={12} />}>
              <Video src={video_url} poster={video_thumb_url} />
            </Suspense>
          </LazyLoad>
        </div>
      )}

      {transcript_full && (
        <Truncate shouldTruncate hideMore short>
          <div className={responseStyles.transcript}>{transcript_full}</div>
        </Truncate>
      )}

      {type === 'audio' && (
        <div className={responseStyles.responseRecording}>
          <LazyLoad once offset={100} placeholder={<Skeleton count={4} />}>
            <Audio url={audio_url} audio_timeline={audio_timeline} />
          </LazyLoad>
        </div>
      )}

      {type === 'text' && (
        <div className={responseStyles.textContainer}>
          <Truncate shouldTruncate hideMore short>
            <Textarea value={text} disabled />
          </Truncate>
        </div>
      )}

      <div className={userStyles.userAction}>
        <ContactCTA targetUser={userDoc.id} />
      </div>
    </div>
  );
}

export default Post;
