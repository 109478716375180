import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from '@/components/Modal/Modal.module.scss';
import Modal from '@/components/Modal/Modal';
import { Button } from '@/components/Button/Button';
import { fetchPost } from '@/hooks/useFetch';
import { useCurrentUserSelector, useDocListSelector } from '@/redux/selectors';
import ToggleGroup from '@/components/ToggleGroup/ToggleGroup';
import _ from '@/lodash';
import { Option, tagsToOptions } from '@/helpers';
import Select from '@/components/Select/Select';

interface IssuesFormProps {
  buttonChildren?: any;
  buttonClassName?: string;
}

function IssuesForm(props: IssuesFormProps) {
  const { buttonChildren = <>Edit</>, buttonClassName = styles.link } = props;

  const dispatch = useDispatch();
  const userDoc = useCurrentUserSelector();

  const issueDocs = useDocListSelector({
    collection: 'tag',
    filter: { category: 'Specialties', is_deprecating: false },
    order: ['position', 'asc'],
  });

  const typeDocs = useDocListSelector({
    collection: 'tag',
    filter: { category: 'Types' },
    order: ['position', 'asc'],
  });

  const issuesAndTypes = tagsToOptions([...typeDocs, ...issueDocs]) as Option[];

  const [issues, setIssues] = useState(userDoc?.issues || []);

  const [topIssues, setTopIssues] = useState(
    _.map(userDoc?.top_issues, (id) => _.find(issuesAndTypes, { value: id })),
  );

  const createSaveChanges = (closeModal: Function) => {
    return () => {
      fetchPost(
        '/api/user',
        { issues, top_issues: _.map(topIssues, 'value') },
        { dispatch },
      );
      closeModal();
    };
  };

  return (
    <Modal
      buttonClassName={buttonClassName}
      buttonChildren={buttonChildren}
      render={({ closeModal }) => (
        <div className={styles.modalForm}>
          <div className={styles.modalHeader}>Your Specialties</div>

          <div className="form-field">
            <span className="form-label">
              Your Top Specialties (Select up to 3)
            </span>
            <Select
              options={issuesAndTypes}
              value={topIssues}
              onChange={setTopIssues}
              placeholder="Select up to 3 top specialties..."
              maxItems={3}
              isMulti
              isSearchable
            />
          </div>

          <div className="form-field">
            <span className="form-label">Additional Specialties</span>

            <ToggleGroup
              value={issues}
              onChange={setIssues}
              options={issuesAndTypes}
            />
          </div>

          <div className="form-actions">
            <Button className="btn" color="grey" onClick={closeModal}>
              Cancel
            </Button>

            <Button className="btn" onClick={createSaveChanges(closeModal)}>
              Save Changes
            </Button>
          </div>
        </div>
      )}
    />
  );
}

export default IssuesForm;
