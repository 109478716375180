import { Helmet } from 'react-helmet-async';
import landingStyles from '../LandingClient/LandingClient.module.scss';
import styles from './NotFound.module.scss';
import { LoadImage } from '@/components/LoadImage/LoadImage';
import headerImage from '../LandingClient/pexels-alleksana.jpeg';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div className={landingStyles.landingPage}>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
        <meta name="robots" content="noindex" />
      </Helmet>

      <div className={landingStyles.landingTop}>
        <div>
          <h1 className={landingStyles.landingTitle}>Page not found.</h1>
          <div className={styles.notFoundSubtitle}>
            The page you are looking for is not available.
          </div>
          <Link className="ctaButton" to="/">
            Back to Home
          </Link>
        </div>

        <div>
          <LoadImage
            src={headerImage}
            alt="Therapist Room"
            className={landingStyles.headerImage}
            preload
          />
        </div>
      </div>
    </div>
  );
}
