import { useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from '@/lodash';
import styles from '@/components/Modal/Modal.module.scss';
import Modal from '@/components/Modal/Modal';
import { Button } from '@/components/Button/Button';
import { useCurrentUserSelector } from '@/redux/selectors';
import { allGenders, Option, valuesToOptions } from '@/helpers';
import Select from '@/components/Select/Select';
import { useForm } from 'react-hook-form';
import { FormInput } from '@/components/FormInput/FormInput';
import { fetchPost } from '@/hooks/useFetch';

interface ProfileFormProps {
  buttonChildren: any;
  buttonClassName?: string;
}

function ProfileForm(props: ProfileFormProps) {
  const { buttonChildren, buttonClassName } = props;
  const userDoc = useCurrentUserSelector();

  const dispatch = useDispatch();

  const initalGender = valuesToOptions(userDoc?.gender) as Option[];

  const [gender, setGender] = useState(initalGender || []);

  const form = useForm({
    defaultValues: {
      first_name: userDoc?.first_name,
      last_name: userDoc?.last_name,
      headline: userDoc?.headline,
      phone: userDoc?.phone,
      license: userDoc?.license,
      link: userDoc?.link,
    },
  });

  const genderOnChange = (selection: Option[]) => {
    const isTrans = _.includes(_.map(selection, 'value'), 'Transgender');
    const validSelection = isTrans
      ? _.take(selection, 2)
      : _.take(selection, 1);

    setGender(validSelection);
  };

  const createSaveChanges = (closeModal: Function) => {
    return (formData: any) => {
      fetchPost(
        '/api/user',
        {
          ..._.mapValues(formData, _.trim),
          gender: _.map(gender, 'value'),
        },
        { dispatch },
      );

      closeModal();
    };
  };

  return (
    <Modal
      buttonClassName={buttonClassName}
      buttonChildren={buttonChildren}
      render={({ closeModal }) => (
        <div className={styles.modalForm}>
          <div className={styles.modalHeader}>Your Profile</div>

          <div className="form-field">
            <span className="form-label">Name</span>
            <div className="form-field-split">
              <FormInput
                form={form}
                name="first_name"
                placeholder="Your first name"
                autoComplete="given-name"
                maxLength={50}
                required
              />

              <FormInput
                form={form}
                name="last_name"
                placeholder="Your last name"
                autoComplete="family-name"
                maxLength={50}
                required
              />
            </div>
          </div>

          <div className="form-field">
            <span className="form-label">Work Title</span>
            <FormInput
              form={form}
              name="headline"
              placeholder="Your work title"
              maxLength={50}
              required
            />
          </div>

          <div className="form-field">
            <span className="form-label">Phone</span>
            <FormInput
              form={form}
              name="phone"
              type="tel"
              autoComplete="tel"
              placeholder="(Optional) Your work phone number"
              maxLength={25}
            />
          </div>

          <div className="form-field">
            <span className="form-label">License</span>
            <FormInput
              form={form}
              name="license"
              placeholder="(Optional) Your license number"
              maxLength={70}
            />
          </div>

          <div className="form-field">
            <span className="form-label">Gender</span>
            <Select
              options={valuesToOptions(allGenders)}
              value={gender}
              onChange={genderOnChange}
              placeholder="(Optional) Your gender"
              isClearable
              isMulti
            />
          </div>

          <div className="form-field">
            <span className="form-label">Website Link</span>
            <FormInput
              form={form}
              name="link"
              type="url"
              placeholder="(Optional) Your practice website"
              maxLength={500}
            />
          </div>

          <div className="form-actions">
            <Button className="btn" color="grey" onClick={closeModal}>
              Cancel
            </Button>

            <Button
              className="btn"
              onClick={form.handleSubmit(createSaveChanges(closeModal))}
            >
              Save Changes
            </Button>
          </div>
        </div>
      )}
    />
  );
}

export default ProfileForm;
