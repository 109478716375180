import { useEffect, useState } from 'react';
import styles from './Evil.module.scss';

interface EvilProps {
  setIsRobot: Function;
}

function Evil(props: EvilProps) {
  const { setIsRobot } = props;
  const [spamText, setSpamText] = useState('');
  const [spamCheck, setSpamCheck] = useState(false);
  const [isHuman, setIsHuman] = useState(false);
  const hasSpam = Boolean(spamText || spamCheck);

  useEffect(() => {
    setIsRobot(hasSpam && !isHuman);
  }, [hasSpam, isHuman, setIsRobot]);

  return (
    <>
      <input
        name="website"
        type="text"
        className={styles.evil}
        autoComplete="off"
        value={spamText}
        onChange={(event) => setSpamText(event.currentTarget.value)}
      />

      <input
        name="url"
        type="text"
        className={styles.evil}
        autoComplete="off"
        value={spamText}
        onChange={(event) => setSpamText(event.currentTarget.value)}
      />

      <input
        name="evil"
        type="checkbox"
        className={styles.evil}
        checked={spamCheck}
        onChange={(event) => setSpamCheck(event.target.checked)}
      />

      {hasSpam && (
        <label className={styles.humanCheck}>
          <input
            name="human"
            type="checkbox"
            checked={isHuman}
            onChange={(event) => setIsHuman(event.target.checked)}
          />
          I am not a robot
        </label>
      )}
    </>
  );
}

export default Evil;
