import _ from '@/lodash';
import ToggleButton from '@/components/ToggleButton/ToggleButton';
import { Option } from '@/helpers';

interface ToggleGroupProps {
  options: string[] | Option[];
  value: string[];
  onChange: Function;
}

function ToggleGroup(props: ToggleGroupProps) {
  const { options, value, onChange: setSelection } = props;
  const selection = value ?? [];
  const isChecked = (option: string) => _.includes(selection, option);

  return (
    <div>
      {_.map(options, (option: string | Option) => {
        const value = _.isString(option) ? option : option?.value;
        const label = _.isString(option) ? option : option?.label;

        const onChange = (event: any) => {
          const { checked } = event.target;

          checked
            ? setSelection([...selection, value])
            : setSelection(_.without(selection, value));
        };

        return (
          <ToggleButton
            checked={isChecked(value)}
            onChange={onChange}
            key={value}
          >
            {label}
          </ToggleButton>
        );
      })}
    </div>
  );
}

export default ToggleGroup;
