import _ from '@/lodash';
import styles from '../Issues/Issues.module.scss';
import profileStyes from '@/layouts/Profile/Profile.module.scss';
import modalStyles from '@/components/Modal/Modal.module.scss';
import { UserDoc } from 'src-server/models';
import { useIsMyself } from '@/redux/selectors';
import FocusesForm from '../FocusesForm/FocusesForm';

interface FocusesProps {
  userDoc: UserDoc;
}

function Focuses(props: FocusesProps) {
  const { userDoc } = props;
  const { id, focuses_age, focuses_sexuality, focuses_ethnicity } = userDoc;
  const isMyself = useIsMyself(id);
  const allFocuses = _.compact(
    _.concat(focuses_age, focuses_sexuality, focuses_ethnicity),
  );

  if (_.isEmpty(allFocuses) && !isMyself) {
    return null;
  }

  return (
    <>
      <div className={profileStyes.sidebarHeader}>
        <span>Client Focuses</span>

        {isMyself && (
          <FocusesForm
            buttonClassName={
              _.size(allFocuses) ? modalStyles.linkComplete : undefined
            }
          />
        )}
      </div>

      <div className={styles.container}>
        {_.isEmpty(allFocuses) ? (
          <div> No listed focuses.</div>
        ) : (
          _.map(allFocuses, (focus) => <div key={focus}>{focus}</div>)
        )}
      </div>
    </>
  );
}

export default Focuses;
