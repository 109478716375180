import _ from '@/lodash';
import { useSearchParams } from 'react-router-dom';
import styles from './QuestionTabs.module.scss';
import { valuesToOptions } from '@/helpers';

function QuestionTabs() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tag = searchParams.get('tag') || 'All';

  const allTags = valuesToOptions([
    'All',
    'Specialties',
    'Approaches',
    'General',
  ]);

  const createOnClick = (tag: string) => () => {
    searchParams.set('tag', tag);
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <div className={styles.questionTabs}>
      {_.map(allTags, ({ label, value }) => (
        <div
          onClick={createOnClick(value)}
          className={tag === value ? styles.active : undefined}
          key={value}
        >
          {label}
        </div>
      ))}
    </div>
  );
}

export default QuestionTabs;
