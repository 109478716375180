import _ from '@/lodash';
import { IoIosAddCircle } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import styles from './ResponseList.module.scss';
import Response from '../Response/Response';
import Modal from '@/components/Modal/Modal';
import { useDocListSelector, useIsMyself } from '@/redux/selectors';
import { fetchPost } from '@/hooks/useFetch';
import ContactCTA from '../ContactCTA/ContactCTA';
import { ResponseDoc, UserDoc } from 'src-server/models';
import QuestionBank from '../QuestionBank/QuestionBank';

interface ResponseListProps {
  userDoc: UserDoc;
}

function ResponseList(props: ResponseListProps) {
  const { userDoc } = props;
  const { id: targetUser, summary, issues, approaches, top_issues } = userDoc;
  const hasTags = _.size([...(issues || []), ...(approaches || [])]);
  const tagDocs = useDocListSelector({ collection: 'tag' });

  const dispatch = useDispatch();
  const isMyself = useIsMyself(targetUser);

  let order;

  const filter: Partial<ResponseDoc> = {
    author_id: targetUser,
    is_deleted: false,
  };

  if (isMyself) {
    order = ['created_at', 'desc'];
  } else {
    order = [
      [
        ({ video_url }: ResponseDoc) => Boolean(video_url),
        ({ tag_id }: ResponseDoc) => {
          let position;
          const tagDoc = _.find(tagDocs, { id: tag_id });

          if (_.includes(top_issues, tag_id)) {
            position = 5;
          } else {
            position = tagDoc?.position || 100;
          }

          return position;
        },
        'created_at',
      ],
      ['desc', 'asc', 'desc'],
    ];

    filter.is_complete = true;
  }

  const responseDocs = useDocListSelector({
    collection: 'response',
    filter,
    order,
  });

  const onClick = (id: string) => {
    fetchPost('/api/response', { question_id: id }, { dispatch });
  };

  const addQuestionInactive = hasTags ? '' : styles.addQuestionInactive;

  return (
    <>
      {isMyself && (
        <Modal
          buttonClassName={`${styles.addQuestion} ${addQuestionInactive}`}
          buttonChildren={
            <>
              <IoIosAddCircle />
              {hasTags ? (
                <span>Answer Questions (Recommended)</span>
              ) : (
                <span>Answer Questions</span>
              )}
            </>
          }
          render={({ closeModal }) => (
            <div className={styles.questionForm}>
              <div className={styles.questionHeader}>
                Pick a Question to Answer
              </div>

              <QuestionBank closeModal={closeModal} onClick={onClick} />
            </div>
          )}
        />
      )}

      {(!summary || isMyself) && _.isEmpty(responseDocs) ? (
        <div className={styles.emptyVideo}>No posts yet.</div>
      ) : (
        _.map(responseDocs, (responseDoc) => (
          <Response responseDoc={responseDoc} key={responseDoc.id} />
        ))
      )}

      {!isMyself && (
        <div className={styles.feedCTA}>
          <div className={styles.feedCTATitle}>Interested in talking?</div>

          <ContactCTA targetUser={targetUser} />
        </div>
      )}
    </>
  );
}

export default ResponseList;
