import styles from './QuestionBank.module.scss';
import QuestionTabs from '../QuestionTabs/QuestionTabs';
import QueryInput from '@/components/QueryInput/QueryInput';
import Paging from '../Paging/Paging';
import QuestionPage from '../QuestionPage/QuestionPage';
import { useCurrentUserSelector, useLoginSelector } from '@/redux/selectors';
import { useSearchParams } from 'react-router-dom';

interface QuestionBankProps {
  closeModal?: any;
  onClick?: Function;
  className?: string;
}

function QuestionBank(props: QuestionBankProps) {
  const { closeModal, onClick, className = styles.questionContainer } = props;

  const userDoc = useCurrentUserSelector();
  const [searchParams] = useSearchParams();
  const tag = searchParams.get('tag');
  const search = searchParams.get('search');

  const createOnClick = (id: string) => {
    return async () => {
      onClick && (await onClick(id));
      closeModal && closeModal();
    };
  };

  return (
    <div className={className}>
      <QueryInput queryName="search" placeholder="Search for a question…" />
      <QuestionTabs />
      <Paging
        component={QuestionPage}
        params={{
          tag,
          search,
          user_id: userDoc?.id,
          is_deprecating: false,
          pageSize: 8,
        }}
        pageProps={{ createOnClick }}
      />
    </div>
  );
}

export default QuestionBank;
