import _ from '@/lodash';
import styles from './Issues.module.scss';
import modalStyles from '@/components/Modal/Modal.module.scss';
import profileStyes from '@/layouts/Profile/Profile.module.scss';
import { UserDoc } from 'src-server/models';
import Tag from '../Tag/Tag';
import { useDocListSelector, useIsMyself } from '@/redux/selectors';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import Tooltip from '@/components/Tooltip/Tooltip';
import IssuesForm from '../IssuesForm/IssuesForm';

interface IssuesProps {
  userDoc: UserDoc;
}

function Issues(props: IssuesProps) {
  const { userDoc } = props;
  const { id, issues, top_issues } = userDoc;
  const isMyself = useIsMyself(id);

  const allTypes = useDocListSelector({
    collection: 'tag',
    filter: { category: 'Types' },
  });

  const allIssues = useDocListSelector({
    collection: 'tag',
    filter: { category: 'Specialties' },
  });

  const issuesAndTypes = [...allIssues, ...allTypes];

  const otherIssues = _.without(issues, ...(top_issues || []));

  const topIssueDocs = _.map(top_issues, (id) =>
    _.find(issuesAndTypes, { id }),
  );
  const issueDocs = _.compact(
    _.map(otherIssues, (id) => _.find(issuesAndTypes, { id })),
  );

  const topIssues = _.compact(_.map(topIssueDocs, 'id'));
  const sortedIssues = _.compact(_.map(_.sortBy(issueDocs, 'label'), 'id'));

  return (
    <>
      <div className={profileStyes.sidebarHeader}>
        <span>Specialties</span>

        {isMyself && (
          <IssuesForm
            buttonClassName={
              _.size(top_issues) ? modalStyles.linkComplete : undefined
            }
          />
        )}
      </div>

      <div className={styles.container}>
        {_.isEmpty(top_issues) && _.isEmpty(issues) ? (
          <div> No listed specialties.</div>
        ) : (
          <>
            {_.map(topIssues, (issue) => (
              <Tooltip
                content="This is one of this therapist's top specialties."
                key={issue}
              >
                <div className={styles.topIssue}>
                  <Tag tag={issue} />
                  <IoIosCheckmarkCircle />
                </div>
              </Tooltip>
            ))}

            {_.map(sortedIssues, (issue) => (
              <div key={issue}>
                <Tag tag={issue} />
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
}

export default Issues;
