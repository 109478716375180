import _ from '@/lodash';
import { Controller } from 'react-hook-form';
import Select, { SelectOptions } from '../Select/Select';
import ToggleGroup from '../ToggleGroup/ToggleGroup';
import Textarea from '../Textarea/Textarea';
import { emailRegex } from '@/helpers';

type FormInputProps = {
  name: string;
  form: any;

  maxLength?: number;
  required?: boolean;

  type?: string;
  className?: string;
  placeholder?: string;
  autoComplete?: string;
  inputMode?: string;

  options?: any;
} & SelectOptions;

export const FormInput = (props: FormInputProps) => {
  const {
    name,
    form,
    maxLength,
    required = false,
    options,
    type = 'text',
    className = props.options ? '' : 'form-input',
    ...otherProps
  } = props;

  const emailPattern = {
    value: emailRegex,
    message: 'Please enter a valid email.',
  };
  const pattern = type === 'email' ? emailPattern : undefined;

  const {
    register,
    formState: { errors },
    watch,
    control,
  } = form;

  const error = errors[name];
  const isLengthError = error?.type === 'maxLength';
  const isRequiredError = error?.type === 'required';
  const isPatternError = error?.type === 'pattern';
  const errorMessage = error?.message;

  return (
    <div>
      {options && type !== 'toggle-group' && (
        <Controller
          name={name}
          control={control}
          rules={{ required }}
          render={({ field }) => (
            <Select
              options={options}
              className={className}
              {..._.omit(field, 'ref')}
              {...otherProps}
            />
          )}
        />
      )}

      {options && type === 'toggle-group' && (
        <Controller
          name={name}
          control={control}
          rules={{ required }}
          render={({ field }) => (
            <ToggleGroup
              options={options}
              {..._.omit(field, 'ref')}
              {...otherProps}
            />
          )}
        />
      )}

      {!options && type !== 'textarea' && (
        <input
          type={type}
          className={className}
          {...otherProps}
          {...register(name, { maxLength, required, pattern })}
        />
      )}

      {!options && type === 'textarea' && (
        <Controller
          name={name}
          control={control}
          rules={{ maxLength, required }}
          render={({ field }) => (
            <Textarea {..._.omit(field, 'ref')} {...otherProps} />
          )}
        />
      )}

      {isLengthError && (
        <div className="form-error">
          {_.size(watch(name))} / {maxLength} characters max
        </div>
      )}

      {isPatternError && <div className="form-error">{errorMessage}</div>}

      {isRequiredError && <div className="form-error">Field is required</div>}
    </div>
  );
};
