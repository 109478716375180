import { useState, Suspense, lazy } from 'react';
import _ from '@/lodash';
import { IoIosMic, IoIosMore, IoIosVideocam, IoMdText } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import LazyLoad from 'react-lazyload';
import styles from './Response.module.scss';
import { ResponseDoc } from 'src-server/models';
import { fetchDelete, fetchPost } from '@/hooks/useFetch';
import Dropdown from '@/components/Dropdown/Dropdown';
import { useIsMyself } from '@/redux/selectors';
import Textarea, { shouldTruncate } from '@/components/Textarea/Textarea';
import { Button } from '@/components/Button/Button';
import Truncate from '@/components/Truncate/Truncate';
import Skeleton from '@/components/Skeleton/Skeleton';
import Audio from '../Audio/Audio';
import { getSlug, isChromium } from '@/helpers';

const Video = lazy(() => import('../../components/Video/Video'));
const Recording = lazy(() => import('../../containers/Recording/Recording'));
interface ResponseProps {
  responseDoc: ResponseDoc;
}

enum DropdownValue {
  DeletePost = 'Delete Post',
  EditPost = 'Edit Post',
}

function Response(props: ResponseProps) {
  const { responseDoc } = props;
  const dispatch = useDispatch();
  const {
    id,
    title,
    video_url,
    video_thumb_url,
    audio_url,
    audio_timeline,
    author_id,
    text: initialText,
    type,
    transcript_full,
  } = responseDoc;

  const [text, setText] = useState(initialText);

  const typeToContent = {
    audio: audio_url,
    video: video_url,
    text: text,
  };
  const hasContent = Boolean(type && typeToContent[type]);

  const [edit, setEdit] = useState(!hasContent);
  const [dropdown, setDropdown] = useState();
  const [cleanup, setCleanup] = useState(false);
  const isMyself = useIsMyself(author_id);

  const deletePost = () => {
    fetchDelete('/api/response', { id }, { dispatch });
  };

  const createSaveType = (type: string) => () => {
    fetchPost('/api/response', { id, type }, { dispatch });
  };

  const onFinishVideo = async () => {
    const updates: any = { id };

    if (type === 'video') {
      updates.video_url = true;
    } else if (type === 'audio') {
      updates.audio_url = true;
    }

    await fetchPost('/api/response', updates, { dispatch });

    setEdit(false);
  };

  const onFinishText = async () => {
    await fetchPost('/api/response', { id, text }, { dispatch });

    setEdit(false);
  };

  if (!hasContent && !isMyself) {
    return null;
  }

  const dropdownValues: DropdownValue[] = [DropdownValue.DeletePost];

  if (!edit) {
    dropdownValues.unshift(DropdownValue.EditPost);
  }

  const dropdownOptions = _.map(dropdownValues, (value) => ({
    label: value,
    value,
  }));

  const dropdownOnChange = (option: any) => {
    const isEditMedia = edit && (type === 'video' || type === 'audio');
    const { value } = option;

    if (value === DropdownValue.EditPost) {
      setEdit(true);
    } else if (value === DropdownValue.DeletePost) {
      isEditMedia ? setCleanup(true) : deletePost();
    }

    setDropdown(value);
  };

  const isSupported = isChromium();
  const notSupportedClass = !isSupported ? styles.notSupported : '';
  const notSupportedText = (
    <>
      This feature is not supported in your browser. We recommend switching to{' '}
      <a
        href="https://www.google.com/chrome/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Google Chrome
      </a>
      .
    </>
  );

  return (
    <div className={styles.section} id={getSlug(title)}>
      <div className={styles.sectionHeader}>
        <div>{title}</div>

        <div className={styles.headerRight}>
          {isMyself && (
            <Dropdown
              buttonChildren={<IoIosMore className={styles.more} />}
              options={dropdownOptions}
              onChange={dropdownOnChange}
              value={dropdown}
            />
          )}
        </div>
      </div>

      {type === 'video' && !edit && (
        <div className={styles.responseVideo}>
          <LazyLoad once offset={100} placeholder={<Skeleton count={4} />}>
            <Suspense fallback={<Skeleton count={12} />}>
              <Video src={video_url} poster={video_thumb_url} />
            </Suspense>
          </LazyLoad>
        </div>
      )}

      {type === 'audio' && !edit && (
        <div className={styles.responseRecording}>
          <LazyLoad once offset={100} placeholder={<Skeleton count={4} />}>
            <Audio url={audio_url} audio_timeline={audio_timeline} />
          </LazyLoad>
        </div>
      )}

      {(type === 'video' || type === 'audio') && edit && (
        <div className={styles.responseRecording}>
          <LazyLoad once offset={100} placeholder={<Skeleton count={4} />}>
            <Suspense fallback={<Skeleton count={12} />}>
              <Recording
                url={video_url}
                type={type}
                response={id}
                onFinish={onFinishVideo}
                cleanup={cleanup}
                afterCleanup={deletePost}
              />
            </Suspense>
          </LazyLoad>
        </div>
      )}

      {transcript_full && (
        <Truncate shouldTruncate toggleText="Read Transcript" short>
          <div className={styles.transcript}>{transcript_full}</div>
        </Truncate>
      )}

      {!type && (
        <div className={styles.typeSelection}>
          <div className={styles.typeRow}>
            <div className={styles.typeOption} onClick={createSaveType('text')}>
              <IoMdText />
              Write an Answer
            </div>

            <div className={styles.typeTip}>
              Write in your genuine voice for the best results.
            </div>
          </div>

          <div className={styles.typeRow}>
            <div
              className={`${styles.typeOption} ${notSupportedClass}`}
              onClick={createSaveType('audio')}
            >
              <IoIosMic />
              Record Voice Answer
            </div>

            <div className={styles.typeTip}>
              {isSupported
                ? 'Most clients prefer listening to answers over reading it.'
                : notSupportedText}
            </div>
          </div>

          <div className={styles.typeRow}>
            <div
              className={`${styles.typeOption} ${notSupportedClass}`}
              onClick={createSaveType('video')}
            >
              <IoIosVideocam />
              Record Video Answer
            </div>

            <div className={styles.typeTip}>
              {isSupported
                ? 'The best way for clients to get a good feel for you.'
                : notSupportedText}
            </div>
          </div>
        </div>
      )}

      {type === 'text' && (
        <div>
          <div className={edit ? styles.textContainer : ''}>
            <Truncate
              shouldTruncate={!isMyself && shouldTruncate({ value: text })}
            >
              <Textarea
                placeholder="Write your answer here…"
                value={text}
                disabled={!isMyself || !edit}
                onChange={setText}
              />
            </Truncate>
          </div>

          {edit && (
            <div className={styles.saveRow}>
              <Button className="btn" onClick={onFinishText} disabled={!text}>
                Save Post
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Response;
