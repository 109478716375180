import { Link } from 'react-router-dom';
import { IoIosCheckmark, IoIosAdd } from 'react-icons/io';

import styles from '../LandingClient/LandingClient.module.scss';

import headerImage from './pexels-teona-swift.jpeg';
import screenshot from './screenshot.png';
import { HeaderTags } from '@/components/HeaderTags/HeaderTags';
import { LoadImage } from '@/components/LoadImage/LoadImage';
import { Preload } from '@/components/Preload/Preload';

function LandingTherapist() {
  return (
    <div className={styles.landingPage}>
      <Preload href={headerImage} />
      <HeaderTags
        title="MintLeaf | Marketing For Therapists & Psychologists"
        description="Attract new clients to your practice online. Get listed on our free directory and grow your practice."
        url="https://mintleaf.co/for-providers"
      />

      <div className={styles.landingTop}>
        <div>
          <h1 className={styles.landingTitle}>
            Get <i>new clients</i> for your therapy practice.
          </h1>

          <Link className="ctaButton" to="/register">
            Get Started — Join for Free!
          </Link>
        </div>

        <div>
          <LoadImage
            src={headerImage}
            alt="Therapist Room"
            className={styles.headerImage}
            preload
          />
        </div>
      </div>

      <div className={styles.landingOverview}>
        <div>
          <h2 className={styles.landingSubtitle}>Answer questions like...</h2>
          <div className={styles.checkGrid}>
            <IoIosAdd />
            <div>
              What advice would you give to those hesitant to try therapy?
            </div>

            <IoIosAdd />
            <div>Why did you become a therapist?</div>

            <IoIosAdd />
            <div>What does a session with you look like?</div>
          </div>
        </div>

        <div>
          <h2 className={styles.landingSubtitle}>
            Find clients that match you.
          </h2>
          <div className={styles.checkGrid}>
            <IoIosCheckmark />
            <div>Get listed for free on our therapist directory.</div>

            <IoIosCheckmark />
            <div>
              Reach clients that resonate with your approach to mental health.
            </div>

            <IoIosCheckmark />
            <div>
              <span className={styles.emphasis}>
                Match with the right clients for your practice.
              </span>
            </div>
          </div>
        </div>

        <div>
          <h2 className={styles.landingSubtitle}>
            {"It's quick and easy to get started."}
          </h2>

          <div className={styles.checkGrid}>
            <IoIosCheckmark />
            <div>Signing up take less than 10 minutes on average.</div>

            <IoIosCheckmark />
            <div>Most users go live on the same day they sign up.</div>

            <IoIosCheckmark />
            <div>
              <span className={styles.emphasis}>
                Show up higher on Google today with better SEO marketing.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.overviewAction}>
        <Link className="ctaButtonSecondary" to="/register">
          Get Started — Join for Free!
        </Link>
      </div>

      <div className={styles.landingVideo}>
        <LoadImage
          src={screenshot}
          alt="MintLeaf Profile Screenshot"
          className={styles.screenshot}
        />

        <div>
          <div className={styles.landingColumn}>
            <h2 className={styles.landingSubtitle}>
              Stand out with a more personal therapist directory.
            </h2>

            <div>
              Traditional directories often only include a blurb or short video
              where you say hello.{' '}
              <i>How are you supposed to stand out that way?</i>
            </div>

            <div>
              Instead of relying on generic hellos, MintLeaf lets you answer
              questions about your approach to mental health instead. This lets
              clients get to know you, helping both sides find the perfect fit.
            </div>

            <div>
              <Link className="ctaButtonSecondary" to="/register">
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className={styles.centerHeader}>Ready to get started?</div>

        <div className={styles.landingSplit}>
          <div className={styles.landingPricing}>
            <div>
              <h2 className={styles.landingSubtitle}>Starting Practice</h2>
              <div className={styles.price}>Free — no charge</div>
            </div>

            <div className="bullets">
              <div>Get listed on our therapist directory.</div>
              <div>
                Stand out by answering questions about your approach to mental
                health.
              </div>
              <div>Match with the right clients for your practice.</div>
              <div>Quick & easy sign up.</div>
            </div>
            <div>
              <Link className="ctaButtonSecondary" to="/register">
                Get Started — Join for Free!
              </Link>
            </div>
          </div>

          <div className={styles.landingPricing}>
            <div>
              <div className={styles.landingSubtitle}>Growth Package</div>
              <div className={styles.price}>$30 / month</div>
            </div>

            <div className="bullets">
              <div>
                <b>Not accepting new growth clients currently.</b>
              </div>
              <div>Run ads for your practice.</div>
              <div>Target clients in your area and specialization.</div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.questionList}>
        <div className={styles.landingSubtitle}>Frequent Questions</div>

        <div>
          <div>
            <span className={styles.question}>
              How is this different from other directories?
            </span>
          </div>

          <div>
            Traditional directories often only include a blurb or short video
            where you say hello.{' '}
            <i>How are you supposed to stand out that way?</i>
          </div>

          <div>
            Instead of relying on generic hellos, MintLeaf lets you answer
            questions about your approach to mental health instead. This lets
            clients get to know you, helping both sides find the perfect fit.
          </div>
        </div>

        <div>
          <div>
            <span className={styles.question}>
              What if I already have a website?
            </span>
          </div>

          <div>
            You have the option to link your MintLeaf profile to your personal
            website. This way your website remains the main destination for your
            clients, and MintLeaf acts as an additional referral source for
            growing your practice.
          </div>
        </div>

        <div>
          <div>
            <span className={styles.question}>What is the MintLeaf Blog?</span>
          </div>
          <div>
            The MintLeaf Blog is a publication focused on mental health where
            every article is written by a therapist. Contributing articles is an
            opportunity for you to get traffic and additional SEO for your
            practice. If you are interested please{' '}
            <a
              href="mailto:team@mintleaf.co"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              contact us
            </a>
            .
          </div>
        </div>
      </div>

      <div className={styles.overviewAction}>
        <Link className="ctaButtonSecondary" to="/register">
          Get Started — Join for Free!
        </Link>
      </div>
    </div>
  );
}

export default LandingTherapist;
