import _ from '@/lodash';
import styles from '../Issues/Issues.module.scss';
import modalStyles from '@/components/Modal/Modal.module.scss';
import profileStyes from '@/layouts/Profile/Profile.module.scss';
import { UserDoc } from 'src-server/models';
import Tag from '../Tag/Tag';
import { useDocListSelector, useIsMyself } from '@/redux/selectors';
import ApproachForm from '../ApproachForm/ApproachForm';

interface ApproachProps {
  userDoc: UserDoc;
}

function Approach(props: ApproachProps) {
  const { userDoc } = props;
  const { id, approaches } = userDoc;
  const isMyself = useIsMyself(id);

  const allApproaches = useDocListSelector({
    collection: 'tag',
    filter: { category: 'Approaches' },
  });

  if (_.isEmpty(approaches) && !isMyself) {
    return null;
  }

  const approachDocs = _.map(approaches, (id) => _.find(allApproaches, { id }));
  const sortedApproaches = _.map(_.sortBy(approachDocs, 'label'), 'id');

  return (
    <>
      <div className={profileStyes.sidebarHeader}>
        <span>Approaches</span>

        {isMyself && (
          <ApproachForm
            buttonClassName={
              _.size(approaches) ? modalStyles.linkComplete : undefined
            }
          />
        )}
      </div>

      <div className={styles.container}>
        {_.isEmpty(approaches) ? (
          <div> No listed approaches.</div>
        ) : (
          _.map(sortedApproaches, (approach) => (
            <div key={approach}>
              <Tag tag={approach} />
            </div>
          ))
        )}
      </div>
    </>
  );
}

export default Approach;
