import _ from '@/lodash';
import styles from './Profile.module.scss';
import Skeleton from '@/components/Skeleton/Skeleton';
import { useIsMyself } from '@/redux/selectors';
import ProfileCard from '@/containers/ProfileCard/ProfileCard';
import ProfileSummary from '@/containers/ProfileSummary/ProfileSummary';
import {
  getDisplayName,
  getProfileLink,
  getShortLocation,
  formatPhone,
  getShortHeadline,
} from '@/helpers';
import Issues from '@/containers/Issues/Issues';
import Approach from '@/containers/Approach/Approach';
import Payments from '@/containers/Payments/Payments';
import OnboardLetter from '@/containers/OnboardLetter/OnboardLetter';
import ResponseList from '@/containers/ResponseList/ResponseList';
import { useLoadDocList } from '@/hooks/useLoadDoc';
import Focuses from '@/containers/Focuses/Focuses';
import { HeaderTags } from '@/components/HeaderTags/HeaderTags';
import { Helmet } from 'react-helmet-async';
import ChecklistOnboard from '@/containers/ChecklistOnboard/ChecklistOnboard';
import Address from '@/containers/Address/Address';
import { UserDoc } from 'src-server/models';

interface ProfileProps {
  userDoc: UserDoc;
}

function Profile(props: ProfileProps) {
  const { userDoc } = props;
  const targetUser = userDoc.id;
  const { isSuccess } = useLoadDocList({
    name: 'Profile',
    collection: 'response',
    query: { author_id: targetUser, pageSize: 20 },
  });

  const isMyself = useIsMyself(targetUser);
  const {
    onboarded,
    onboarded_letter,
    flagged,
    flagged_keyword,
    summary,
    phone,
    licensed_states,
  } = userDoc;

  if (isMyself && !onboarded) {
    return <ChecklistOnboard />;
  }

  const displayName = getDisplayName(userDoc);
  const headline = getShortHeadline(userDoc);
  const location = getShortLocation(userDoc);

  const description = _.truncate(
    `${displayName}, ${headline}, ${location}. ${summary}`,
    { length: 160 },
  );

  return (
    <div className={styles.profilePage}>
      <ProfileCard userDoc={userDoc} />

      <div className={styles.feed}>
        {isMyself && !onboarded_letter && <OnboardLetter />}

        <ProfileSummary userDoc={userDoc} />

        <div className="mobile-only">
          <div className={styles.mobileOverview}>
            <Address userDoc={userDoc} />

            <Payments userDoc={userDoc} />
          </div>
        </div>

        {isSuccess ? (
          <ResponseList userDoc={userDoc} />
        ) : (
          <Skeleton card count={8} />
        )}
      </div>

      <div>
        <div className="desktop-only">
          <Address userDoc={userDoc} />
          <Payments userDoc={userDoc} />
        </div>

        <Issues userDoc={userDoc} />
        <Focuses userDoc={userDoc} />
        <Approach userDoc={userDoc} />
      </div>

      <HeaderTags
        title={`${displayName}, ${headline}, ${location} | MintLeaf Therapy`}
        description={description}
        url={`https://mintleaf.co${getProfileLink(userDoc)}`}
        noIndex={flagged || flagged_keyword}
        state={
          _.size(licensed_states) === 1 ? _.first(licensed_states) : undefined
        }
      />

      {phone && !flagged && !flagged_keyword && (
        <Helmet>
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "Person",
                "@id": "${`https://mintleaf.co${getProfileLink(userDoc)}`}",
                "name": "${displayName}",
                "telephone": "${formatPhone(phone)}"
              }
            `}
          </script>
        </Helmet>
      )}
    </div>
  );
}

export default Profile;
