import classNames from 'classnames';
import { IoIosArrowRoundForward } from 'react-icons/io';
import Skeleton from '@/components/Skeleton/Skeleton';
import { useLoadDoc } from '@/hooks/useLoadDoc';

import responseStyles from '../Response/Response.module.scss';
import styles from './QuestionRow.module.scss';

interface QuestionRowProps {
  question: string;
  createOnClick: any;
  headerChildren?: any;
}

function QuestionRow(props: QuestionRowProps) {
  const { question, createOnClick, headerChildren } = props;
  const { doc: questionDoc, isSuccess } = useLoadDoc({
    name: 'QuestionRow',
    collection: 'question',
    id: question,
  });

  if (!isSuccess || !questionDoc) {
    return <Skeleton card count={3} />;
  }

  const { title, description, id } = questionDoc;

  return (
    <div
      className={classNames(responseStyles.section, styles.selectable)}
      key={id}
      onClick={createOnClick(id)}
    >
      <div className={responseStyles.sectionHeader}>
        {title}

        <div className={responseStyles.headerRight}>{headerChildren}</div>
      </div>

      <div className={responseStyles.sectionText}>{description}</div>

      <div className={styles.selectQuestion}>
        <span>Answer Question</span>
        <IoIosArrowRoundForward />
      </div>
    </div>
  );
}

export default QuestionRow;
