import { createAction } from '@reduxjs/toolkit';

export interface LoginPayload {
  id: string | null;
  short_id?: string | null;
  url_tag?: string | null;
}

export const loginAction = createAction<LoginPayload>('loginAction');
export const logoutAction = createAction('logoutAction');
export const loadDocsAction = createAction<any>('loadDocsAction');
