import { useState } from 'react';
import styles from '../Login/Login.module.scss';
import Tooltip from '@/components/Tooltip/Tooltip';
import { Button } from '@/components/Button/Button';
import Evil from '@/components/Evil/Evil';
import { fetchPost } from '@/hooks/useFetch';
import { Link, useSearchParams } from 'react-router-dom';
import { HeaderTags } from '@/components/HeaderTags/HeaderTags';
import { useDispatch } from 'react-redux';
import { logoutAction } from '@/redux/actions';

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isRobot, setIsRobot] = useState(false);
  const [searchParams] = useSearchParams();

  const user_id = searchParams.get('id');
  const token = searchParams.get('token');
  const hasToken = user_id && token;
  const isFilled = Boolean(hasToken ? password : email);
  const dispatch = useDispatch();

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isRobot) {
      setErrorMessage('Please check the box to confirm you are human.');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');

    if (hasToken) {
      const message =
        'Reset failed, your link may have expired. Please request a new password reset.';

      fetchPost('/auth/reset_password', { user_id, token, password })
        .then(() => {
          setIsSuccess(true);
          dispatch(logoutAction());
        })
        .catch(() => setErrorMessage(message))
        .finally(() => setIsLoading(false));
    } else {
      fetchPost('/auth/request_reset', { email });
      setIsSuccess(true);
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={onSubmit} className={styles.loginForm}>
      <HeaderTags
        title="Forgot Password | MintLeaf"
        description="Log back in to your MintLeaf therapist profile."
        url="https://mintleaf.co/reset"
        noIndex
      />

      <h1 className={styles.loginHeader}>
        {hasToken && !isSuccess && 'Create new password'}
        {hasToken && isSuccess && 'New password set'}
        {!hasToken && !isSuccess && 'Forgot password'}
        {!hasToken && isSuccess && 'Reset requested'}
      </h1>

      {hasToken && isSuccess && (
        <div>
          Your password has been reset. You can use your new password to{' '}
          <Link to="/login" className="link primary">
            login
          </Link>{' '}
          to your account.
        </div>
      )}

      {!hasToken && isSuccess && (
        <>
          <div>
            If the email you entered matches an account, a password reset link
            will be on its way shortly.
          </div>

          <div>
            If you do not see it in your inbox, please check your spam folder or
            try again later.
          </div>
        </>
      )}

      {!isSuccess && (
        <>
          <div className={styles.resetSubheader}>
            {hasToken
              ? `Please enter the new password that you would like for your account.`
              : `Please enter the email address associated with your account.`}
          </div>

          <div className={styles.loginFields}>
            <div className="form-field">
              {hasToken && (
                <>
                  <span className="form-label">New Password</span>
                  <input
                    name="password"
                    type="password"
                    className={styles.loginInput}
                    placeholder="Your New Password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(event) => setPassword(event.currentTarget.value)}
                  />
                </>
              )}

              {!hasToken && (
                <>
                  <span className="form-label">Email</span>
                  <input
                    name="email"
                    type="email"
                    className={styles.loginInput}
                    placeholder="Your Email"
                    autoComplete="new-password"
                    value={email}
                    onChange={(event) => setEmail(event.currentTarget.value)}
                  />
                </>
              )}
            </div>
          </div>

          <Evil setIsRobot={setIsRobot} />

          {errorMessage && (
            <div className={styles.loginError}>{errorMessage}</div>
          )}

          <div className={styles.passwordRow}>
            <Tooltip
              content="Please fill out all fields and checkboxes."
              disabled={isFilled}
            >
              <Button
                className="btn"
                type="submit"
                disabled={!isFilled || isLoading}
              >
                {hasToken ? 'Save New Password' : 'Email me a Password Reset'}
              </Button>
            </Tooltip>
          </div>
        </>
      )}
    </form>
  );
}

export default ResetPassword;
