import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Button } from '../Button/Button';
import styles from './Truncate.module.scss';

interface TruncateProps {
  children?: any;
  shouldTruncate?: boolean;
  toggleText?: string;
  short?: boolean;
  hideMore?: boolean;
}

function Truncate(props: TruncateProps) {
  const {
    children,
    shouldTruncate,
    toggleText = 'See More',
    short,
    hideMore,
  } = props;

  const [truncated, setTruncated] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!truncated && shouldTruncate) {
      setTruncated(true);
    }
  }, [truncated, shouldTruncate]);

  const toggleExpanded = (event: any) => {
    event.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <>
      {truncated && !expanded && (
        <div className={styles.truncateContainer}>
          <div
            className={classNames(styles.verticalTruncate, {
              [styles.short]: short,
            })}
          >
            {children}
          </div>

          {!hideMore && (
            <Button className={styles.toggleLink} onClick={toggleExpanded}>
              {toggleText}
            </Button>
          )}
        </div>
      )}

      {(!truncated || expanded) && children}
    </>
  );
}

export default Truncate;
