import { useLoadDocList } from '@/hooks/useLoadDoc';

function LoadTags() {
  useLoadDocList({
    name: 'LoadTags',
    collection: 'tag',
    query: { pageSize: 200 },
    isCacheOnce: true,
  });

  return null;
}

export default LoadTags;
