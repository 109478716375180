import { lazy, Suspense, useState } from 'react';

const ControlledModal = lazy(async () => {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return import('./ControlledModal');
});

interface ModalProps {
  buttonClassName?: string;
  buttonChildren: any;
  render: (params: any) => any;
  match?: any;
  location?: any;
  history?: any;
  disabled?: any;
  beforeClose?: Function;
}

function Modal(props: ModalProps) {
  const { buttonClassName, buttonChildren, render, disabled, beforeClose } =
    props;

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    beforeClose && beforeClose();
    setIsOpen(false);
  };

  return (
    <>
      <div
        className={buttonClassName}
        onClick={disabled ? undefined : openModal}
      >
        {buttonChildren}
      </div>

      <Suspense fallback={null}>
        <ControlledModal
          closeModal={closeModal}
          isOpen={isOpen}
          render={render}
        />
      </Suspense>
    </>
  );
}

export default Modal;
