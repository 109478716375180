import _ from '@/lodash';
import { IoIosAddCircleOutline } from 'react-icons/io';
import styles from './ProfileCard.module.scss';
import { UserDoc } from 'src-server/models';
import { getDisplayName } from '@/helpers';
import UserBadge from '../UserBadge/UserBadge';
import ContactCTA from '../ContactCTA/ContactCTA';
import { Button } from '@/components/Button/Button';
import { UserChip } from '../UserChip/UserChip';
import { useIsMyself } from '@/redux/selectors';
import Tooltip from '@/components/Tooltip/Tooltip';
import ProfileForm from '../ProfileForm/ProfileForm';
import BadgeForm from '../BadgeForm/BadgeForm';

interface ProfileCardProps {
  userDoc: UserDoc;
}

function ProfileCard(props: ProfileCardProps) {
  const { userDoc } = props;
  const { headline, license, link: rawLink, photo } = userDoc;
  const targetUser = userDoc.id;
  const isMyself = useIsMyself(targetUser);

  const link = _.trim(rawLink);
  const displayName = getDisplayName(userDoc);
  const displayLink = _.includes(link, 'http') ? link : `http://${link}`;
  const displayLocation = getDisplayLocation(userDoc);

  return (
    <div className={styles.profileCard}>
      {isMyself ? (
        <BadgeForm
          buttonClassName={styles.badgeContainer}
          buttonChildren={
            <>
              <UserBadge
                user={targetUser}
                className={styles.profileBadge}
                preload
              />

              {!photo && (
                <Button className={styles.addPhoto} color="primary">
                  <IoIosAddCircleOutline />
                  <span>Add Photo</span>
                </Button>
              )}
            </>
          }
        />
      ) : (
        <div className={styles.badgeContainer}>
          <UserBadge
            user={targetUser}
            className={styles.profileBadge}
            preload
          />
        </div>
      )}

      <div className={styles.profileInfo}>
        <div className={styles.profileName}>
          <h1>{displayName}</h1>
        </div>

        <UserChip userDoc={userDoc} />

        <h1 className="faded">{headline || 'Therapist'}</h1>

        {displayLocation && <h1 className="faded">{displayLocation}</h1>}

        {license && <div className="faded">License {_.trim(license)}</div>}

        {link && (
          <a
            href={displayLink}
            target="_blank"
            rel="noopener noreferrer ugc"
            className="faded truncate"
          >
            {link}
          </a>
        )}
      </div>

      <div className={styles.profileAction}>
        {isMyself && (
          <ProfileForm
            buttonClassName={styles.editProfile}
            buttonChildren={<>Edit Profile</>}
          />
        )}

        <ContactCTA targetUser={targetUser} />
      </div>
    </div>
  );
}

function getDisplayLocation(userDoc: UserDoc) {
  const { city, state, licensed_states } = userDoc;
  const locations = _.uniq(
    _.compact([city, state, ...(licensed_states || [])]),
  );

  let display;

  if (_.size(locations) > 3) {
    display = (
      <Tooltip content={_.slice(locations, 3).join(', ')}>
        <>
          {_.take(locations, 3).join(', ') +
            `, and ${_.size(locations) - 3} more...`}
        </>
      </Tooltip>
    );
  } else {
    display = locations.join(', ');
  }

  return display;
}

export default ProfileCard;
