import styles from './State.module.scss';
import Paging from '@/containers/Paging/Paging';
import UserPage from '@/containers/UserPage/UserPage';
import {
  useAbsoluteUrl,
  valuesToOptions,
  Option,
  getStateUrl,
  getStatePayments,
  allGenders,
  insuranceStates,
  tagsToOptions,
  getSlug,
  shortenDC,
  allEthnicityFocuses,
  useSearchableIssues,
  useSearchableApproaches,
  getSlugOption,
  getTagSlugOption,
} from '@/helpers';
import Select from '@/components/Select/Select';
import _ from '@/lodash';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { HeaderTags } from '@/components/HeaderTags/HeaderTags';
import { useEffect, useMemo, useState } from 'react';

interface StateProps {
  state: string;
}

const allPrices = [
  '$ — Less than $90',
  '$$ — $90 - 130',
  '$$$ — More than $130',
];

const allTeletherapy = ['Online Therapy'];

function State(props: StateProps) {
  const { state } = props;
  const { tag: slug } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const allPayments = useMemo(() => getStatePayments(state), [state]);

  const issueDocs = useSearchableIssues();
  const approachDocs = useSearchableApproaches();

  const [issue, setIssue] = useState<Option>();
  const [approach, setApproach] = useState<Option>();
  const [gender, setGender] = useState<Option>();
  const [payment, setPayment] = useState<Option>();
  const [teletherapy, setTeletherapy] = useState<Option>();
  const [price, setPrice] = useState<Option>();
  const [ethnicity, setEthnicity] = useState<Option>();

  const tagsLoaded = _.size(issueDocs) && _.size(approachDocs);

  useEffect(() => {
    if (!tagsLoaded) {
      return;
    }

    const searchIssue = searchParams.get('issue') || slug;
    const searchApproach = searchParams.get('approach') || slug;
    const searchGender = searchParams.get('gender') || slug;
    const searchPayment = searchParams.get('payment') || slug;
    const searchTeletherapy = searchParams.get('teletherapy') || slug;
    const searchPrice = searchParams.get('price') || slug;
    const searchEthnicity = searchParams.get('ethnicity') || slug;

    const newIssue = getTagSlugOption(issueDocs, searchIssue);
    const newApproach = getTagSlugOption(approachDocs, searchApproach);
    const newGender = getSlugOption(allGenders, searchGender);
    const newPayment = getSlugOption(allPayments, searchPayment);
    const newTeletherapy = getSlugOption(allTeletherapy, searchTeletherapy);
    const newPrice = getSlugOption(allPrices, searchPrice);
    const newEthnicity = getSlugOption(allEthnicityFocuses, searchEthnicity);

    setIssue(newIssue);
    setApproach(newApproach);
    setGender(newGender);
    setPayment(newPayment);
    setTeletherapy(newTeletherapy);
    setPrice(newPrice);
    setEthnicity(newEthnicity);
  }, [allPayments, approachDocs, issueDocs, searchParams, slug, tagsLoaded]);

  useEffect(() => {
    if (!tagsLoaded) {
      return;
    }

    const slugIssue = getTagSlugOption(issueDocs, slug);
    const slugApproach = getTagSlugOption(approachDocs, slug);
    const slugGender = getSlugOption(allGenders, slug);
    const slugPayment = getSlugOption(allPayments, slug);
    const slugTeletherapy = getSlugOption(allTeletherapy, slug);
    const slugPrice = getSlugOption(allPrices, slug);
    const slugEthnicity = getSlugOption(allEthnicityFocuses, slug);

    const isValidSlug =
      slugIssue ||
      slugApproach ||
      slugGender ||
      slugPayment ||
      slugTeletherapy ||
      slugPrice ||
      slugEthnicity;

    if (slug && !isValidSlug) {
      navigate(getStateUrl(state));
    }
  }, [allPayments, approachDocs, issueDocs, navigate, slug, state, tagsLoaded]);

  const userParams = {
    state: shortenDC(state),
    teletherapy: teletherapy ? true : undefined,
    issues: issue ? [issue.value] : undefined,
    gender: gender ? [gender.value] : undefined,
    payments: payment ? [payment.value] : undefined,
    approaches: approach ? [approach.value] : undefined,
    focuses_ethnicity: ethnicity ? [ethnicity.value] : undefined,
    price: price?.value || undefined,
    search: true,
    tag_ids: _.compact([issue?.value, approach?.value]),
    pageSize: 7,
  };

  const stateLabel = shortenDC(state);
  const onlineLabel = teletherapy ? 'Online ' : '';
  const tagOption = issue || gender || payment || approach;
  const tagLabel = tagOption?.label ? `${tagOption?.label} ` : '';
  const trimmedTagLabel = _.replace(tagLabel, 'Therapy', '');
  const title = `${onlineLabel}${trimmedTagLabel}Therapists in ${stateLabel}`;

  const metaDescription =
    'Find the right therapist for you. Each profile lists the insurances the provider takes, typical costs per session, and how to get in contact.';

  const isInsuranceFilterable = _.includes(insuranceStates, state);
  const filterStyles = isInsuranceFilterable
    ? styles.filterListThree
    : styles.filterListFour;

  const paramHasState = (paramName: string) => {
    const mapping: any = {
      issue,
      approach,
      gender,
      payment,
      teletherapy,
      price,
      ethnicity,
    };

    return Boolean(mapping[paramName]);
  };

  const createOnChange = (paramName: string) => {
    return (option?: Option) => {
      const value = getSlug(option?.label);
      let removeFirstTag = false;

      if (value) {
        if (paramHasState(paramName) && !searchParams.has(paramName)) {
          removeFirstTag = true;
        }

        searchParams.set(paramName, value);
      } else {
        if (searchParams.has(paramName)) {
          searchParams.delete(paramName);
        } else {
          removeFirstTag = true;
        }
      }

      searchParams.delete('page');

      const firstTag =
        (removeFirstTag ? '' : slug) ||
        searchParams.get('issue') ||
        searchParams.get('approach') ||
        searchParams.get('gender') ||
        searchParams.get('payment') ||
        searchParams.get('teletherapy') ||
        searchParams.get('ethnicity');

      searchParams.forEach((value, key) => {
        if (value === firstTag) {
          searchParams.delete(key);
        }
      });

      navigate({
        pathname: getStateUrl(state, firstTag || ''),
        search: `?${searchParams}`,
      });
    };
  };

  return (
    <div className={styles.searchPage}>
      <HeaderTags
        title={`${title} | MintLeaf`}
        description={metaDescription}
        url={useAbsoluteUrl()}
        state={state}
      />

      <div>
        <h1 className={styles.pageTitle}>{title}</h1>
        <div className={styles.pageSubtitle}>{metaDescription}</div>
      </div>

      <div className={filterStyles}>
        <Select
          options={tagsToOptions(issueDocs)}
          value={issue}
          onChange={createOnChange('issue')}
          placeholder="Specialties"
          isSearchable={false}
          isClearable
          isOutline
        />

        <Select
          options={tagsToOptions(approachDocs)}
          value={approach}
          onChange={createOnChange('approach')}
          placeholder="Types of Therapy"
          isSearchable={false}
          isClearable
          isOutline
        />

        {isInsuranceFilterable && (
          <Select
            options={valuesToOptions(
              _.without(getStatePayments(state), 'Sliding Scale'),
            )}
            value={payment}
            onChange={createOnChange('payment')}
            placeholder="Insurance"
            isSearchable={false}
            isClearable
            isOutline
          />
        )}

        <Select
          options={valuesToOptions(allTeletherapy)}
          value={teletherapy}
          onChange={createOnChange('teletherapy')}
          placeholder="Online Therapy"
          isSearchable={false}
          isClearable
          isOutline
        />

        <Select
          options={valuesToOptions(allPrices)}
          value={price}
          onChange={createOnChange('price')}
          placeholder="Price"
          isSearchable={false}
          isClearable
          isOutline
        />

        {/* <Select
          options={valuesToOptions(allGenders)}
          value={gender}
          onChange={createOnChange('gender')}
          placeholder="Gender"
          isSearchable={false}
          isClearable
          isOutline
        />

        <Select
          options={valuesToOptions(allEthnicityFocuses)}
          value={ethnicity}
          onChange={createOnChange('ethnicity')}
          placeholder="Ethnicity"
          isSearchable={false}
          isClearable
          isOutline
        /> */}
      </div>

      <Paging
        component={UserPage}
        params={userParams}
        showAllPages={false}
        className={styles.searchResults}
      />
    </div>
  );
}

export default State;
