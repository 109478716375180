import _ from '@/lodash';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import styles from './QueryInput.module.scss';
import { useMemo, useEffect, useState } from 'react';

interface QueryInputProps {
  queryName: string;
  placeholder: string;
  autoFocus?: boolean;
  className?: string;
}

function QueryInput(props: QueryInputProps) {
  const { queryName, placeholder, autoFocus, className } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const [input, setInput] = useState(searchParams.get(queryName) || '');

  const updateParams = useMemo(
    () =>
      _.debounce((newInput: string) => {
        searchParams.set(queryName, newInput);
        setSearchParams(searchParams);
      }, 500),
    [queryName, searchParams, setSearchParams],
  );

  useEffect(() => {
    updateParams(input);
  }, [input, updateParams]);

  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    setInput(event.currentTarget.value);
  };

  return (
    <input
      className={classNames(styles.searchBar, className)}
      placeholder={placeholder}
      onChange={onChange}
      value={input}
      autoFocus={autoFocus}
    />
  );
}

export default QueryInput;
