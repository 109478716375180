import styles from './SearchPost.module.scss';
import stateStyles from '../State/State.module.scss';
import Paging from '@/containers/Paging/Paging';
import {
  askTherapists,
  allStates,
  Option,
  useAbsoluteUrl,
  valuesToOptions,
} from '@/helpers';
import Select from '@/components/Select/Select';
import { useState } from 'react';
import _ from '@/lodash';
import UserPage from '@/containers/UserPage/UserPage';
import { HeaderTags } from '@/components/HeaderTags/HeaderTags';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { useDocListSelector } from '@/redux/selectors';

function SearchPost() {
  const { slug } = useParams();
  const [state, setState] = useState<Option>();
  const [searchParams, setSearchParams] = useSearchParams();
  const tagDocs = useDocListSelector({ collection: 'tag' });
  const tagDoc = _.find(tagDocs, { slug });
  const absoluteUrl = useAbsoluteUrl();

  if (_.size(tagDocs) && !tagDoc) {
    return <Navigate to="/" />;
  }

  if (!tagDoc) {
    return null;
  }

  const { id: tag_id } = tagDoc;
  const { seoTitle, pageTitle } = _.find(askTherapists, { tag_id }) || {};
  const params = {
    tag_ids: [tag_id],
    search: true,
    pageSize: 10,
    state: state?.value,
  };

  const onChange = (value: Option) => {
    setState(value);
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  return (
    <div className={stateStyles.searchPage}>
      <HeaderTags
        title={`${seoTitle} | MintLeaf`}
        description={`Therapists answer: ${pageTitle}`}
        url={absoluteUrl}
      />

      <div>
        <div className={styles.therapistsAnswer}>Therapists answer…</div>
        <h1 className={stateStyles.pageTitle}>{pageTitle}</h1>
      </div>

      <div className={stateStyles.filterListTwo}>
        <Select
          options={valuesToOptions(allStates)}
          value={state}
          onChange={onChange}
          placeholder="Filter by State"
          isSearchable
          isClearable
          isOutline
        />
      </div>

      <Paging
        component={UserPage}
        params={params}
        showAllPages={false}
        className={stateStyles.searchResults}
      />
    </div>
  );
}

export default SearchPost;
