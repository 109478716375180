import { useEffect, useState } from 'react';
import _ from '@/lodash';
import { isProduction } from './Tracker';
import { loadDocsAction } from '../redux/actions';
import qs from 'qs';
import { captureException } from '@sentry/react';

const baseURL = isProduction ? 'https://mintleaf.co' : 'http://localhost:8080';

export async function rawFetch(rawUrl: string, options?: RequestInit) {
  return fetch(rawUrl, options).then(async (response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const contentType = response.headers.get('Content-Type');
    const isJSON = contentType?.includes('application/json');

    return isJSON ? await response.json() : await response.text();
  });
}

async function apiFetch(url: string, options?: RequestInit) {
  return rawFetch(baseURL + url, {
    ...options,
    body: options?.body ? JSON.stringify(options.body) : undefined,
    credentials: isProduction ? 'same-origin' : 'include',
    headers: { 'Content-Type': 'application/json' },
  }).catch((error) => {
    if (!isProduction) {
      console.error(error);
    }

    throw error;
  });
}

export async function fetchDelete(
  url: string,
  params: any,
  options?: { dispatch?: Function },
) {
  return apiFetch(url, { method: 'DELETE', body: params }).then((data) => {
    loadDocs(url, params, options, data);
    return data;
  });
}

export async function fetchPost(
  url: string,
  params: any,
  options?: { dispatch?: Function },
) {
  return apiFetch(url, { method: 'POST', body: params }).then((data) => {
    loadDocs(url, params, options, data);
    return data;
  });
}

export async function fetchGet(
  url: string,
  params?: any,
  options?: { dispatch?: Function },
) {
  const queryString = qs.stringify(params);
  const fullUrl = `${url}?${queryString}`;

  return apiFetch(fullUrl, { method: 'GET' }).then((data) => {
    loadDocs(fullUrl, params, options, data);
    return data;
  });
}

export function useFetchGet(
  url: string,
  latestParams: any,
  options: {
    name: string;
    reloadOnChange?: boolean;
    reloadCallback?: Function;
    cachedResult?: any;
    dispatch?: Function;
  },
) {
  const cachedResult = options?.cachedResult;
  const reloadOnChange = options?.reloadOnChange;
  const reloadCallback = options?.reloadCallback;
  const name = options?.name;
  const dispatch = options?.dispatch;

  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [result, setResult] = useState<any>(undefined);
  const [allResults, setAllResults] = useState<any>(undefined);
  const [params, setParams] = useState(latestParams);
  const [cache, setCache] = useState(cachedResult);

  if (reloadOnChange && !_.isEqual(params, latestParams)) {
    setCache(null);
    setParams(latestParams);
    reloadCallback && reloadCallback();
  }

  useEffect(() => {
    let isMounted = true;

    setIsSuccess(false);

    if (!_.isEmpty(cache)) {
      setResult({ docs: [cache] });
      setIsSuccess(true);
    } else {
      fetchGet(url, params, { dispatch })
        .then((data) => {
          if (isMounted) {
            if (!isProduction) {
              console.log({ name, url, params, data });
            }

            const updates = data?.updates;
            const firstResult = updates ? _.first(updates) : data;

            setResult(firstResult);

            if (updates) {
              setAllResults(updates);
            }

            setIsSuccess(true);
          }
        })
        .catch((error) => {
          if (isMounted) {
            setError(error);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [url, params, cache, name, dispatch]);

  return { isSuccess, error, result, allResults, setParams };
}

function loadDocs(
  url: string,
  params: any,
  options?: { dispatch?: Function },
  result?: any,
) {
  const dispatch = options?.dispatch;

  if (dispatch) {
    const updates = result?.updates;

    if (updates) {
      _.each(updates, (update) => dispatch(loadDocsAction(update)));
    } else if (result?.docs) {
      dispatch(loadDocsAction(result));
    } else {
      captureException(new Error(`Invalid endpoint result: ${url}`), {
        extra: { params },
      });
    }
  }
}
