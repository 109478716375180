import { lazy, Suspense } from 'react';

const LazyTippy = lazy(async () => {
  await new Promise((resolve) => setTimeout(resolve, 3000));
  return import('./LazyTippy');
});

export default function Tooltip(props: any) {
  return (
    <Suspense fallback={props.children}>
      <LazyTippy {...props} />
    </Suspense>
  );
}
