import _ from '@/lodash';
import { useDocSelector } from '@/redux/selectors';

interface TagProps {
  tag?: string;
}

function Tag(props: TagProps) {
  const { tag } = props;
  const tagDoc = useDocSelector({ collection: 'tag', id: tag });

  if (!tagDoc || tagDoc.is_deprecating) {
    return null;
  }

  return <>{tagDoc.label}</>;
}

export default Tag;
