import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import isbot from 'isbot';
import { init, browserTracingIntegration } from '@sentry/react';
import ReactGA from 'react-ga4';

const { hostname } = window.location;
const userAgent = window?.navigator?.userAgent;

export const isBot = isbot(userAgent);
export const isProduction = hostname === 'mintleaf.co';

const shouldTrack = isProduction && !isBot;

if (shouldTrack) {
  ReactGA.initialize('G-ZHTETNZ3X1');

  init({
    dsn: 'https://af254f58bec842a386eab4deef1cd3a6@o321985.ingest.sentry.io/5200282',
    integrations: [browserTracingIntegration()],
    tracesSampleRate: 0.05,
    tracePropagationTargets: ['https://mintleaf.co'],
  });
}

export default function Tracker() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (shouldTrack) {
      ReactGA.set({ page: pathname });
    }
  }, [pathname]);

  return null;
}
