import { useState } from 'react';
import _ from '@/lodash';
import Textarea, { shouldTruncate } from '@/components/Textarea/Textarea';
import { useDocListSelector, useIsMyself } from '@/redux/selectors';
import { fetchPost } from '@/hooks/useFetch';
import Truncate from '@/components/Truncate/Truncate';

import responseStyles from '../Response/Response.module.scss';
import { ResponseDoc, UserDoc } from 'src-server/models';
import { Button } from '@/components/Button/Button';
import { isMobile } from '@/helpers';

interface ProfileSummaryProps {
  userDoc: UserDoc;
}

function ProfileSummary(props: ProfileSummaryProps) {
  const { userDoc } = props;
  const { id, first_name } = userDoc;
  const isMyself = useIsMyself(id);

  const filter: Partial<ResponseDoc> = {
    author_id: id,
    is_complete: true,
    is_deleted: false,
  };

  const responseDocs = useDocListSelector({
    collection: 'response',
    filter,
  });
  const hasResponses = _.size(responseDocs);

  const [summary, setSummary] = useState(userDoc.summary || '');
  const [isChanged, setIsChanged] = useState(false);

  const saveSummary = () => {
    fetchPost('/api/user', { summary });
    setIsChanged(false);
  };

  const updateSummary = (newSummary: any) => {
    setSummary(newSummary);
    setIsChanged(true);
  };

  if (!isMyself && !summary) {
    return null;
  }

  const expandOnDesktop = !hasResponses && !isMobile();

  return (
    <div className={responseStyles.section}>
      <div className={responseStyles.sectionHeader}>About {first_name}</div>

      <div>
        <div className={isMyself ? responseStyles.textContainer : ''}>
          <Truncate
            shouldTruncate={
              !isMyself &&
              !expandOnDesktop &&
              shouldTruncate({ value: summary })
            }
          >
            <Textarea
              placeholder="Give a quick introduction about yourself."
              value={summary}
              disabled={!isMyself}
              onChange={updateSummary}
            />
          </Truncate>
        </div>

        {isChanged && isMyself && (
          <div className={responseStyles.saveRow}>
            <Button className="btn" onClick={saveSummary}>
              Save Post
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfileSummary;
