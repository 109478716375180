import _ from '@/lodash';

interface LoadImageProps {
  src?: string;
  alt?: string;
  className?: string;
  preload?: boolean;
}

export function LoadImage(props: LoadImageProps) {
  const { src, alt, className, preload } = props;

  return (
    <>
      {preload && (
        <img
          src={src}
          alt={alt}
          className={className}
          // @ts-ignore
          // eslint-disable-next-line react/no-unknown-property
          fetchpriority="high"
        />
      )}

      {!preload && (
        <img
          src={src}
          alt={alt}
          className={className}
          // @ts-ignore
          // eslint-disable-next-line react/no-unknown-property
          fetchpriority="auto"
        />
      )}
    </>
  );
}
