import { UserDoc } from 'src-server/models';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { hasInPerson, hasTeletherapy } from '@/helpers';
import Tooltip from '@/components/Tooltip/Tooltip';
import styles from './UserChip.module.scss';

interface UserChipProps {
  userDoc: UserDoc;
}

export function UserChip(props: UserChipProps) {
  const { userDoc } = props;
  const { min_cost = 0 } = userDoc;
  const teletherapy = hasTeletherapy(userDoc);
  const inPerson = hasInPerson(userDoc);
  let teletherapyTip = '';
  let teletherapyLabel = '';
  let priceLabel = '';

  if (teletherapy && inPerson) {
    teletherapyTip =
      'This therapist is available for both virtual sessions and in-person therapy.';
    teletherapyLabel = 'Online & In-Person';
  } else if (teletherapy) {
    teletherapyTip =
      'This therapist is available for virtual sessions of therapy.';
    teletherapyLabel = 'Online Therapy';
  } else if (inPerson) {
    teletherapyTip = 'This therapist is available for in-person therapy.';
    teletherapyLabel = 'In-Person Therapy';
  }

  if (!teletherapyLabel) {
    return null;
  }

  if (min_cost < 90) {
    priceLabel = '$';
  } else if (min_cost >= 90 && min_cost <= 130) {
    priceLabel = '$$';
  } else if (min_cost > 130) {
    priceLabel = '$$$';
  }

  return (
    <div className={styles.chipRow}>
      <div className={styles.userChip}>
        <IoIosCheckmarkCircleOutline />
        <Tooltip content={teletherapyTip}>
          <>{teletherapyLabel}</>
        </Tooltip>
      </div>

      {/* <div className={styles.userChip}>Price {priceLabel}</div> */}
    </div>
  );
}
