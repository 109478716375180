import _ from '@/lodash';
import { Helmet } from 'react-helmet-async';

interface PreloadProps {
  href?: string;
}

export function Preload(props: PreloadProps) {
  const { href } = props;

  return (
    <Helmet>
      <link rel="preload" fetchPriority="high" as="image" href={href} />
    </Helmet>
  );
}
