import { useDispatch } from 'react-redux';
import { Button } from '@/components/Button/Button';
import { fetchPost } from '@/hooks/useFetch';
import { useCurrentUserSelector } from '@/redux/selectors';
import styles from './OnboardLetter.module.scss';

function OnboardLetter() {
  const dispatch = useDispatch();
  const userDoc = useCurrentUserSelector();

  if (!userDoc) {
    return null;
  }

  const { first_name } = userDoc;

  const onClick = () => {
    fetchPost(
      '/api/user',
      { id: userDoc.id, onboarded_letter: true },
      { dispatch },
    );
  };

  return (
    <div className={styles.onboardLetter}>
      <div className={styles.onboardHeader}>
        {`Congrats ${first_name}, you've created your profile!`}
      </div>

      <div>
        Next we recommeding filling out your specialties and answering a few
        questions about them. Giving both text and video answers helps clients
        get the best idea of what you are like.
      </div>

      <div>
        {`Once you've filled out your profile and verified your email you'll be live!`}
      </div>

      <div className={styles.onboardFooter}>
        <div className="faded">
          Warm wishes, <br />
          Your friends at MintLeaf
        </div>

        <Button className="btn" onClick={onClick}>
          OK, Got it
        </Button>
      </div>
    </div>
  );
}

export default OnboardLetter;
