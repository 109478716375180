/* eslint-disable react/no-unescaped-entities */

import { Link } from 'react-router-dom';
import { HeaderTags } from '@/components/HeaderTags/HeaderTags';
import Legalmattic from '@/components/Legalmattic/Legalmattic';

export default function LegalTerms() {
  return (
    <div className="legal-container">
      <HeaderTags noIndex />

      <div>
        <h1 className="legal-heading">Terms of Use</h1>
        <i> Last updated: July 20th, 2020 </i>
        <Legalmattic />
      </div>
      <div>
        Please read these Terms of Use ("Terms", "Terms of Use") carefully
        before using https://mintleaf.co (the "Site", or the "Service") operated
        by Birch, LLC ("us", "we", "our"). Our Services are offered subject to
        your acceptance, without modification, of all of the terms and
        conditions contained herein and all other operating rules, policies
        (including, without limitation, the Privacy Policy). Please read the
        Agreement carefully before accessing or using our Services. By accessing
        or using any part of our Services, you agree to become bound by the
        Agreement. If you do not agree to all the terms of the Agreement, then
        you may not access or use our Services.
      </div>

      <div>
        <div className="legal-heading faded">Your Account</div>
        Where use of our Services requires an account, you agree to provide us
        with complete and accurate information when you register for an account.
        You will be solely responsible and liable for any activity that occurs
        under your account. You are responsible for keeping your account
        information up-to-date and for keeping your password secure.
        <br />
        <br />
        You are responsible for maintaining the security of your account and any
        Service-related website, store, or other content, and you are fully
        responsible for all activities that occur under your account and any
        other actions taken in connection with our Services. You shall not share
        or misuse your access credentials. You must immediately notify us of any
        unauthorized uses of your account, store, or website, or of any other
        breach of security. We will not be liable for any acts or omissions by
        you, including any damages of any kind incurred as a result of such acts
        or omissions.
        <br />
        <br />
        When you create an account on our Service, we consider that to be an
        inquiry about our products and services, which means that we may contact
        you to share more details about what we have to offer. Don’t worry — if
        you aren’t interested in learning more, you can opt out of the marketing
        communication, whether it’s an email, phone call, or text message.
      </div>

      <div>
        <div className="legal-heading faded">
          Responsibility of Visitors and Users
        </div>
        We have not reviewed, and cannot review, all of the content (such as,
        but not limited to, text, photo, video, audio, or other materials)
        posted to our Services by users or anyone else (“Content”) and are not
        responsible for any use or effects of such Content. So, for example:
        <ul>
          <li>
            We do not endorse any Content or represent that Content is accurate,
            useful, or non-harmful. Content could be offensive, indecent, or
            objectionable; include technical inaccuracies, typographical
            mistakes, or other errors; or violate or infringe the privacy,
            publicity rights, intellectual property rights, or other proprietary
            rights of third parties.
          </li>
          <li>
            If you post Content, comment on a website, or otherwise make (or
            allow any third party to make) Content available on our Services,
            you are entirely responsible for the Content, and any harm resulting
            from, that Content or your conduct.
          </li>
          <li>
            We disclaim any responsibility for any harm resulting from anyone’s
            use, purchase, or downloading of Content. If you access or use any
            Content, you are responsible for taking precautions as necessary to
            protect yourself and your computer systems from viruses, worms,
            Trojan horses, and other harmful or destructive content.
          </li>
          <li>
            We are not a party to, and will have no responsibility or liability
            for, any communications, transactions, interactions, or disputes
            between you and the provider of any Content.
          </li>
          <li>
            Please note that additional third party terms and conditions may
            apply to the downloading, copying, purchase, or use of Content.
          </li>
        </ul>
        We also have not reviewed, and cannot review, all of the material,
        including computer software, made available through the websites and web
        pages that link to, or are linked from, our Service. For example:
        <ul>
          <li>
            We do not have any control over those websites and are not
            responsible for their contents or their use.
          </li>
          <li>
            The existence of a link to or from one of our Services does not
            represent or imply that we endorse such website.
          </li>
          <li>
            You are responsible for taking precautions as necessary to protect
            yourself and your computer systems from viruses, worms, Trojan
            horses, and other harmful or destructive content.
          </li>
          <li>
            We disclaim any responsibility for any harm resulting from 3rd party
            websites.
          </li>
        </ul>
      </div>

      <div>
        <div className="legal-heading faded">Fees, Payment, and Renewal</div>
        <ul>
          <li>
            Some of our Services are offered for a fee (“Paid Services”). By
            using a Paid Service, you agree to pay the specified fees. Depending
            on the Paid Service, there may be a one-time fee, recurring fees, or
            revenue-based fee. For recurring fees, we’ll bill or charge you for
            in regular automatically-renewing intervals (such as monthly,
            annually, or biennially), on a pre-pay basis until you cancel, which
            you can do at any time by contacting the relevant support team.
          </li>
          <li>
            To the extent permitted by law, or unless explicitly stated
            otherwise, all fees are exclusive of applicable federal, provincial,
            state, local or other governmental sales, goods and services,
            harmonized or other taxes, fees, or charges now in force or enacted
            in the future (“Taxes”). You are responsible for payment of all
            applicable Taxes relating to your use of our Services, your
            payments, or your purchases. If we are obligated to pay or collect
            Taxes on the Fees you’ve paid or will pay, you are responsible for
            such Taxes, and we may collect payment for such Taxes.
          </li>
          <li>
            If your payment fails or if Paid Services are otherwise not paid for
            or paid for on time, we may immediately cancel or revoke your access
            to the Paid Services. If you contact your bank or credit card
            company to decline or reverse the charge of fees for Paid Services,
            we may revoke your access to our Services in general.
          </li>
          <li>
            To ensure uninterrupted service, recurring Paid Services are
            automatically renewed. This means that unless you cancel a Paid
            Service before the end of the applicable subscription period, it
            will automatically renew, and you authorize us to invoice you or use
            any payment mechanism we have on record for you to collect the
            then-applicable subscription fee (as well as any Taxes). We may
            charge your account up to one month before the end of the
            subscription period. It is your responsibility to make sure you are
            checking your email (including any spam or other filters) in order
            to receive any reminder email we send to you in advance of renewal.
            The date for the automatic renewal is determined automatically based
            on the date of the original purchase and cannot be changed.
          </li>
          <li>
            We may change our fees at any time, or start charging fees for
            Services that were previously free. When applicable, we may give you
            advance notice of the fee changes. If you don’t agree with the fee
            changes, you must cancel your Paid Service.
          </li>
          <li>
            While you may cancel a Paid Service at any time, refunds are issued
            in our sole discretion, unless otherwise required by applicable law.
          </li>
        </ul>
      </div>

      <div>
        <div className="legal-heading faded">
          General Representation and Warranty
        </div>
        You represent and warrant that your use of our Services:
        <ul>
          <li>Will be in strict accordance with these Terms;</li>
          <li>
            Will comply with all applicable laws and regulations (including,
            without limitation, all applicable laws regarding online conduct and
            acceptable content, privacy, data protection, and the transmission
            of technical data exported from the United States or the country in
            which you reside);
          </li>
          <li>
            Will not use the Services for any unlawful purposes, to publish
            illegal content, or in furtherance of illegal activities;
          </li>
          <li>
            Will not infringe or misappropriate the intellectual property rights
            of any third party;
          </li>
          <li>
            Will not overburden our systems, as determined by us in our sole
            discretion;
          </li>
          <li>Will not disclose sensitive personal information of others;</li>
          <li>Will not be used to send spam or bulk unsolicited messages;</li>
          <li>
            Will not interfere with, disrupt, or attack any service or network;
            and
          </li>
          <li>
            Will not be used to create, distribute, or enable material that is –
            or that facilitates or operates in conjunction with – malware,
            spyware, adware, or other malicious programs or code.
          </li>
        </ul>
      </div>

      <div>
        <div className="legal-heading faded">License</div>
        By submitting Content to our Service, you grant Birch LLC a world-wide,
        royalty-free, non-exclusive, irrevocable, perpetual and sub-licensable
        license to use, reproduce, distribute, display (including for commercial
        purposes), modify, and adapt the Content. This license also allows Birch
        LLC to make any publicly-posted Content available to third parties
        selected by Birch LLC.
      </div>

      <div>
        <div className="legal-heading faded">Prohibited Uses</div>
        By using our Service, you represent and warrant that your Content and
        conduct do not violate the{' '}
        <Link className="link" to="/legal/user-guidelines">
          User Guidelines
        </Link>
        .
      </div>

      <div>
        <div className="legal-heading faded">Termination</div>
        We may terminate or suspend access to our Service immediately, without
        prior notice or liability, for any reason whatsoever, including without
        limitation if you breach the Terms. All provisions of the Terms which by
        their nature should survive termination shall survive termination,
        including, without limitation, ownership provisions, warranty
        disclaimers, indemnity and limitations of liability.
      </div>

      <div>
        <div className="legal-heading faded">Changes</div>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material we will try to provide at
        least 30 days' notice prior to any new terms taking effect. What
        constitutes a material change will be determined at our sole discretion.
      </div>

      <div>
        <div className="legal-heading faded">Limitation of Liability</div>
        In no event will Birch LLC, or its suppliers or licensors, be liable
        with respect to any subject matter of the Agreement under any contract,
        negligence, strict liability or other legal or equitable theory for: (i)
        any special, incidental or consequential damages; (ii) the cost of
        procurement for substitute products or services; (iii) for interruption
        of use or loss or corruption of data; or (iv) for any amounts that
        exceed $50 or the fees paid by you to Automattic under the Agreement
        during the twelve (12) month period prior to the cause of action,
        whichever is greater. Automattic shall have no liability for any failure
        or delay due to matters beyond their reasonable control. The foregoing
        shall not apply to the extent prohibited by applicable law.
      </div>

      <div>
        <div className="legal-heading faded">Indemnification</div>
        You agree to indemnify and hold harmless Birch LLC, its contractors, and
        its licensors, and their respective directors, officers, employees, and
        agents from and against any and all losses, liabilities, demands,
        damages, costs, claims, and expenses, including attorneys’ fees, arising
        out of or related to your use of our Services, including but not limited
        to your violation of the Agreement, Content that you post, and any
        ecommerce activities conducted through your or another user’s website.
      </div>

      <div>
        <div className="legal-heading faded">Disclaimer of Warranties</div>
        Our Services are provided “as is.” Birch LLC and its suppliers and
        licensors hereby disclaim all warranties of any kind, express or
        implied, including, without limitation, the warranties of
        merchantability, fitness for a particular purpose and non-infringement.
        Neither Birch LLC, nor its suppliers and licensors, makes any warranty
        that our Services will be error free or that access thereto will be
        continuous or uninterrupted. You understand that you download from, or
        otherwise obtain content or services through, our Services at your own
        discretion and risk.
      </div>
    </div>
  );
}
