import { lazy, Suspense, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './BadgeForm.module.scss';
import modalStyles from '@/components/Modal/Modal.module.scss';
import Modal from '@/components/Modal/Modal';
import { Button } from '@/components/Button/Button';
import { uploadToS3 } from '@/helpers';
import { fetchPost } from '@/hooks/useFetch';
import FilePicker from '@/components/FilePicker/FilePicker';

const ImageCropper = lazy(
  () => import('../../components/ImageCropper/ImageCropper'),
);

interface BadgeFormProps {
  buttonChildren: any;
  buttonClassName?: string;
}

function BadgeForm(props: BadgeFormProps) {
  const { buttonChildren, buttonClassName } = props;

  const [inputFile, setInputFile] = useState();
  const [croppedFile, setCroppedFile] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();

  const onChange = (file: any) => {
    setCroppedFile(file);
  };

  const createSaveChanges = (closeModal: Function) => {
    return async () => {
      setIsSaving(true);

      await uploadToS3('/uploads/user', {}, [croppedFile]);
      await fetchPost('/api/user', { photo: true }, { dispatch });

      setIsSaving(false);

      closeModal();
    };
  };

  const beforeClose = () => {
    setInputFile(undefined);
    setCroppedFile(undefined);
  };

  return (
    <Modal
      buttonClassName={buttonClassName}
      buttonChildren={buttonChildren}
      beforeClose={beforeClose}
      render={({ closeModal }) => (
        <div className={styles.badgeForm}>
          <div>
            <div className={modalStyles.modalHeader}>Your Photo</div>

            <div className={modalStyles.modalSubheader}>
              Choose a picture of yourself to show on your profile. Clients are
              much more likely to click on profiles with a photo!
            </div>
          </div>

          {inputFile && (
            <Suspense fallback={null}>
              <ImageCropper file={inputFile} onChange={onChange} />
            </Suspense>
          )}

          <FilePicker
            file={inputFile}
            setFile={setInputFile}
            accept="image/png, image/jpeg, image/webp"
          />

          <div className="form-actions">
            <Button
              className="btn"
              color="grey"
              onClick={closeModal}
              disabled={isSaving}
            >
              Cancel
            </Button>

            {isSaving ? (
              <Button className="btn" disabled>
                Saving...
              </Button>
            ) : (
              <Button
                className="btn"
                onClick={createSaveChanges(closeModal)}
                disabled={!croppedFile}
              >
                Save Changes
              </Button>
            )}
          </div>
        </div>
      )}
    />
  );
}

export default BadgeForm;
