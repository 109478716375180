import _ from '@/lodash';
import { IoIosArrowBack, IoIosArrowForward, IoIosMore } from 'react-icons/io';
import classNames from 'classnames';
import styles from './PagingNav.module.scss';
import { Button } from '@/components/Button/Button';

interface PagingNavProps {
  page: number;
  createUpdatePage: Function;
  next?: number[];
}

function PagingNav(props: PagingNavProps) {
  const { page, createUpdatePage, next } = props;

  const min = Math.max(page - 1, 1);
  const range = _.flatten(_.compact(_.concat(_.range(min, page + 1), next)));

  return (
    <div className={styles.footer}>
      {page > 1 && (
        <Button
          className={styles.footerButton}
          onClick={createUpdatePage(page - 1)}
          aria-label="Previous"
        >
          <IoIosArrowBack />

          <span className="desktop-only">Previous</span>
        </Button>
      )}

      {page > 4 && (
        <div
          className={styles.pageNumber}
          onClick={createUpdatePage(1)}
          key={1}
        >
          1
        </div>
      )}

      {page > 4 && <IoIosMore className={styles.dots} />}

      {_.map(range, (currPage) => (
        <div
          className={classNames(styles.pageNumber, {
            [styles.active]: currPage === page,
          })}
          onClick={createUpdatePage(currPage)}
          key={currPage}
        >
          {currPage}
        </div>
      ))}

      {next && (
        <Button
          className={styles.footerButton}
          onClick={createUpdatePage(page + 1)}
          aria-label="Next"
        >
          <span className="desktop-only">Next</span>

          <IoIosArrowForward />
        </Button>
      )}
    </div>
  );
}

export default PagingNav;
