import { useState } from 'react';
import styles from './FilePicker.module.scss';

interface FilePickerProps {
  file: any;
  setFile: Function;
  accept: string;
  sizeLimitInMB?: number;
}

function FilePicker(props: FilePickerProps) {
  const { file, setFile, accept, sizeLimitInMB = 30 } = props;
  const [hasError, setHasError] = useState(false);

  const fileOnChange = (event: any) => {
    const newFile = event.target.files[0];
    const sizeLimitInBytes = sizeLimitInMB * 1000000;

    if (!newFile) {
      return;
    }

    if (newFile.size > sizeLimitInBytes) {
      setHasError(true);
    } else {
      setFile(newFile);
      setHasError(false);
    }
  };

  return (
    <>
      <div className="form-field">
        <span className="form-label">Choose a File</span>
        <div className={styles.fileContainer}>
          <input
            className={styles.hideInput}
            type="file"
            onChange={fileOnChange}
            accept={accept}
          />
          {file ? '1 file selected' : 'Select a file...'}
        </div>
      </div>

      {hasError && (
        <div className="form-error">
          Please choose a file less than {sizeLimitInMB}MB in size.
        </div>
      )}
    </>
  );
}

export default FilePicker;
