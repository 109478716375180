import Legalmattic from '@/components/Legalmattic/Legalmattic';
import { HeaderTags } from '@/components/HeaderTags/HeaderTags';

export default function LegalGuidelines() {
  return (
    <div className="legal-container">
      <HeaderTags noIndex />

      <div>
        <h1 className="legal-heading">User Guidelines</h1>
        <i> Last updated: January 17th, 2020 </i>
        <Legalmattic />
      </div>

      <div>
        <div className="legal-heading faded">Illegal content and conduct.</div>
        Self-explanatory.
      </div>

      <div>
        <div className="legal-heading faded">
          Technologically harmful content.
        </div>
        Please don’t upload or link to malware, spyware, adware, or other
        malicious or destructive code.
      </div>

      <div>
        <div className="legal-heading faded">Posting private information.</div>
        Don’t share someone’s personal information without their consent, this
        should be obvious.
      </div>

      <div>
        <div className="legal-heading faded">
          Spam or machine-generated content.
        </div>
        You know what this is, but in general, sites such as those primarily
        dedicated to drive traffic to third party sites, boost SEO, phish,
        spoof, or promote affiliate marketing aren’t cool.
      </div>

      <div>
        <div className="legal-heading faded">Other Notes</div>
        Bear in mind that these are just guidelines -- interpretations are
        solely up to us. These guidelines are not exhaustive and are subject to
        change.
      </div>
    </div>
  );
}
