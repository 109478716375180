import { useDispatch } from 'react-redux';
import _ from '@/lodash';
import styles from '@/components/Modal/Modal.module.scss';
import Modal from '@/components/Modal/Modal';
import { Button } from '@/components/Button/Button';
import { fetchPost } from '@/hooks/useFetch';
import { useCurrentUserSelector } from '@/redux/selectors';
import { getUserPayments } from '@/helpers';
import { FormInput } from '@/components/FormInput/FormInput';
import { useForm } from 'react-hook-form';

interface PaymentsFormProps {
  buttonChildren?: any;
  buttonClassName?: string;
}

function PaymentsForm(props: PaymentsFormProps) {
  const { buttonChildren = <>Edit</>, buttonClassName = styles.link } = props;

  const dispatch = useDispatch();
  const userDoc = useCurrentUserSelector();

  const form = useForm({
    defaultValues: {
      payments: userDoc?.payments,
      min_cost: userDoc?.min_cost,
      max_cost: userDoc?.max_cost,
    },
  });

  const createSaveChanges = (closeModal: Function) => {
    return (formData: any) => {
      const { min_cost, max_cost } = formData;

      fetchPost(
        '/api/user',
        {
          ...formData,
          min_cost: _.toNumber(min_cost),
          max_cost: _.toNumber(max_cost),
        },
        { dispatch },
      );

      closeModal();
    };
  };

  return (
    <Modal
      buttonClassName={buttonClassName}
      buttonChildren={buttonChildren}
      render={({ closeModal }) => (
        <div className={styles.modalForm}>
          <div className={styles.modalHeader}>Costs and Insurance</div>

          <div className="form-field">
            <span className="form-label">Cost per Session</span>
            <div className="form-field-split">
              <FormInput
                form={form}
                name="min_cost"
                type="number"
                placeholder="Minimum cost"
                maxLength={4}
                required
              />

              <FormInput
                form={form}
                name="max_cost"
                type="number"
                placeholder="Maximum cost"
                maxLength={4}
                required
              />
            </div>
          </div>

          <div className="form-field">
            <span className="form-label">Payment Options</span>
            <FormInput
              type="toggle-group"
              form={form}
              name="payments"
              options={getUserPayments(userDoc)}
            />
          </div>

          <div className="form-actions">
            <Button className="btn" color="grey" onClick={closeModal}>
              Cancel
            </Button>

            <Button
              className="btn"
              onClick={form.handleSubmit(createSaveChanges(closeModal))}
            >
              Save Changes
            </Button>
          </div>
        </div>
      )}
    />
  );
}

export default PaymentsForm;
