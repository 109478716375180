import styles from './LandingClient.module.scss';

import headerImage from './pexels-alleksana.jpeg';
import {
  valuesToOptions,
  allStates,
  Option,
  getStateUrl,
  useSearchableIssues,
  useSearchableApproaches,
  aOrAn,
} from '@/helpers';
import Select from '@/components/Select/Select';
import { IoIosAdd, IoIosArrowForward } from 'react-icons/io';
import { Link, useNavigate, useParams } from 'react-router-dom';
import _ from '@/lodash';
import { HeaderTags } from '@/components/HeaderTags/HeaderTags';
import { Helmet } from 'react-helmet-async';
import { LoadImage } from '@/components/LoadImage/LoadImage';
import { Preload } from '@/components/Preload/Preload';
import LoadTags from '@/containers/LoadTags/LoadTags';
import { useEffect } from 'react';

function LandingClient() {
  const navigate = useNavigate();
  const { tag: slug } = useParams();

  const issueDocs = useSearchableIssues();
  const approachDocs = useSearchableApproaches();
  const tagsLoaded = _.size(issueDocs) && _.size(approachDocs);
  const allTags = [...issueDocs, ...approachDocs];
  const tagDoc = _.find(allTags, { slug });
  const tagLabel = tagDoc?.label;
  const trimmedTagLabel = _.replace(tagLabel, 'Therapy', '');

  const onChange = (option: Option) => {
    navigate(getStateUrl(option.value, tagLabel));
  };

  useEffect(() => {
    if (slug && tagsLoaded && !tagDoc) {
      navigate('/therapists');
    }
  }, [navigate, slug, tagDoc, tagsLoaded]);

  return (
    <div className={styles.landingPage}>
      <Preload href={headerImage} />
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@graph": [
                {
                  "@type": "Organization",
                  "name": "MintLeaf Therapy",
                  "alternateName": "MintLeaf",
                  "sameAs": [
                    "https://www.facebook.com/MintLeafTherapy/",
                    "https://www.instagram.com/mintleaf_therapy/",
                    "https://www.linkedin.com/company/mintleaf/",
                    "https://twitter.com/mintleaftherapy"
                  ],
                  "url": "https://mintleaf.co/",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://mintleaf.co/logo.png"
                  }
                }
              ]
            }
          `}
        </script>
      </Helmet>

      <HeaderTags
        title="MintLeaf | Find a Therapist, Psychologist, Counselor"
        description="Find the right therapist for you. See how each therapist talks about their approach to mental health."
        url="https://mintleaf.co"
      />

      {slug && <LoadTags />}

      <div className={styles.landingTop}>
        <div>
          <h1 className={styles.landingTitle}>
            {!tagLabel && (
              <>
                Find the therapist <br /> that is right for <br /> <i> you</i>.
              </>
            )}

            {tagLabel && (
              <>
                Find {aOrAn(trimmedTagLabel)} {trimmedTagLabel} Therapist.
              </>
            )}
          </h1>

          <div className={styles.landingActions}>
            <Select
              options={valuesToOptions(allStates)}
              value={null}
              onChange={onChange}
              placeholder="Find Therapists by State"
              isSearchable
              isOutline
            />
          </div>
        </div>

        <div>
          <LoadImage
            src={headerImage}
            alt="Therapist Room"
            className={styles.headerImage}
            preload
          />
        </div>
      </div>

      <div className={styles.landingOverview}>
        <div>
          <div className={styles.landingSubtitle}>
            Find therapists by state/specialty.
          </div>

          <div>Search for therapists licensed in your state.</div>

          <br />

          <div>
            Find ones that specialize in issues like anxiety, depression,
            stress, and relationship issues.
          </div>
        </div>

        <div>
          <div className={styles.landingSubtitle}>See therapists answer...</div>

          <div>
            <div className={styles.checkGrid}>
              <IoIosAdd />
              <div>What does therapy with you look like?</div>

              <IoIosAdd />
              <div>What are some approaches for dealing with anxiety?</div>

              <IoIosAdd />
              <div>How can a therapist help me with depression?</div>
            </div>
          </div>
        </div>

        <div>
          <div className={styles.landingSubtitle}>
            {"Reach out when you're ready."}
          </div>

          <div>
            Take your time reading answers until you find the right match.
          </div>

          <br />

          <div>
            <span className={styles.emphasis}>
              Find a therapist that you can connect with by seeing how they talk
              about your issues.
            </span>
          </div>
        </div>
      </div>

      <div className={styles.statesContainer}>
        <div className={styles.statesHeader}>Find Therapists by State</div>

        <div className={styles.statesGrid}>
          {_.map(allStates, (state) => (
            <Link
              key={getStateUrl(state, tagLabel)}
              to={getStateUrl(state, tagLabel)}
              className={styles.stateLink}
            >
              {state}
            </Link>
          ))}
        </div>
      </div>

      <div className={styles.landingProviders}>
        <div className={styles.landingSubtitle}>
          Are you a mental health professional or group?
        </div>

        <div>
          Get all the resources you need to build a thriving therapy practice.
          We are accepting new providers and practioners across{' '}
          <b>all U.S. states</b>, including ones not currently listed.
        </div>

        <div>
          <Link className={styles.providerLearnMore} to="/for-providers">
            <span>Learn more about membership</span>
            <IoIosArrowForward />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LandingClient;
