import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from '@/components/Modal/Modal.module.scss';
import Modal from '@/components/Modal/Modal';
import { Button } from '@/components/Button/Button';
import { fetchPost } from '@/hooks/useFetch';
import { useCurrentUserSelector, useDocListSelector } from '@/redux/selectors';
import ToggleGroup from '@/components/ToggleGroup/ToggleGroup';
import _ from '@/lodash';
import { tagsToOptions, Option } from '@/helpers';

interface ApproachFormProps {
  buttonClassName?: string;
}

function ApproachForm(props: ApproachFormProps) {
  const { buttonClassName = styles.link } = props;
  const userDoc = useCurrentUserSelector();
  const approachDocs = useDocListSelector({
    collection: 'tag',
    filter: { category: 'Approaches', is_deprecating: false },
    order: ['position', 'asc'],
  });

  const [approaches, setApproaches] = useState(userDoc?.approaches || []);
  const dispatch = useDispatch();

  const createSaveChanges = (closeModal: Function) => {
    return () => {
      fetchPost('/api/user', { approaches }, { dispatch });
      closeModal();
    };
  };

  return (
    <Modal
      buttonClassName={buttonClassName}
      buttonChildren={<>Edit</>}
      render={({ closeModal }) => (
        <div className={styles.modalFormWide}>
          <div className={styles.modalHeader}>Your Approaches</div>

          <ToggleGroup
            value={approaches}
            onChange={setApproaches}
            options={tagsToOptions(approachDocs) as Option[]}
          />

          <div className="form-actions">
            <Button className="btn" color="grey" onClick={closeModal}>
              Cancel
            </Button>

            <Button className="btn" onClick={createSaveChanges(closeModal)}>
              Save Changes
            </Button>
          </div>
        </div>
      )}
    />
  );
}

export default ApproachForm;
